import React, { useEffect } from "react";
import Footer from "./Footer";
import Navbar from "../navbar/Navbar";
import "../../css/Page.css";
import Sidebar from "../page_components/Sidebar";
import { useWindowSize } from "../../util/util";
import { useDispatch, useSelector } from "react-redux";
import * as CmsActions from "../../store/actions/cms";

function Page(props) {
  const dispatch = useDispatch();
  const { servicePages, isGermanMode, landingPage, accommodationsPage, contactPage, getInvolvedPage, historyPage, newsPage, staffApplicationPage, staffPage } = useSelector((state) => state.cms);

  useEffect(() => {
    let parentSlug = window.location.pathname.split("/")[1];
    switch (parentSlug) {
      case "services":
        if (servicePages) {
          let tmp = servicePages.filter((el) => el.slug === window.location.pathname);
          if (tmp[0]) dispatch(CmsActions.setSerivceCms(tmp[0]));
        }
        break;

      default:
        break;
    }
  }, [servicePages, isGermanMode, window.location.pathname]);

  // defines the allowable sidebar types coming in from the parent.  If the page type
  // doesn't match one of the values in the Sidebar type object (in the sidebar component) then it will cause the page not to render.
  const legalSidebarTypes = ["contact", "accommodations", "services", "getInvolved"];

  // eslint-disable-next-line
  const [width, height] = useWindowSize();

  const sidebarState = () => {
    if (!props.sidebarType) return "";
    if (width > 950 && props.sidebarType) return "hasSidebar shown";
    if (width <= 950 && props.sidebarType) return "hasSidebar expandable";
  };

  return (
    <div className={isGermanMode ? "german" : "notGerman"}>
      <Navbar />
      <div className="pageWrapper">
        <Sidebar sidebarType={legalSidebarTypes.includes(props.sidebarType) ? props.sidebarType : "none"} />

        <main id="pageContent" className={sidebarState()}>
          {props.children}
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default Page;
