// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.golfBanner {
    display: flex;
    height: 500px;
    max-width: 1000px;
    margin: 16px auto;
}

.golfBanner div {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
}

.golfBanner .center {
    background-color: var(--light);
    transform: scale(1.1);
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

.golfBanner .left {
    margin-right: -40px;
}

.golfBanner .right {
    margin-left: -40px;
}

@media (max-width: 570px) {
    .golfBanner .left {
        margin-right: -160px;
    }
    .golfBanner .right {
        margin-left: -160px;
    }
}

@media (min-width: 1200px) {
    .golfBanner .center p {
        font-size: 1.15em;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/GolfPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;IACrB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,oBAAoB;IACxB;IACA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".golfBanner {\n    display: flex;\n    height: 500px;\n    max-width: 1000px;\n    margin: 16px auto;\n}\n\n.golfBanner div {\n    height: 100%;\n    width: 100%;\n    border-radius: 8px;\n    box-shadow: var(--box-shadow);\n}\n\n.golfBanner .center {\n    background-color: var(--light);\n    transform: scale(1.1);\n    padding: 32px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    overflow: auto;\n}\n\n.golfBanner .left {\n    margin-right: -40px;\n}\n\n.golfBanner .right {\n    margin-left: -40px;\n}\n\n@media (max-width: 570px) {\n    .golfBanner .left {\n        margin-right: -160px;\n    }\n    .golfBanner .right {\n        margin-left: -160px;\n    }\n}\n\n@media (min-width: 1200px) {\n    .golfBanner .center p {\n        font-size: 1.15em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
