import React from "react";
import { useSelector } from "react-redux";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

function Navbar() {
  const { mainNav } = useSelector((state) => state.cms);

  return (
    <>
      <DesktopNavbar links={mainNav ? mainNav.links : []} />
      <MobileNavbar links={mainNav ? mainNav.links : []} />
    </>
  );
}

export default Navbar;
