// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RichText p {
  line-height: 32px;
  max-width: 1000px;
}

.RichText h1,
.RichText h2,
.RichText h3 {
  font-family: "EB Garamond", serif !important;
}

.RichText a {
  font-weight: 400;
  text-decoration: underline;
}

.RichText img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/css/RichText.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;;;EAGE,4CAA4C;AAC9C;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".RichText p {\n  line-height: 32px;\n  max-width: 1000px;\n}\n\n.RichText h1,\n.RichText h2,\n.RichText h3 {\n  font-family: \"EB Garamond\", serif !important;\n}\n\n.RichText a {\n  font-weight: 400;\n  text-decoration: underline;\n}\n\n.RichText img {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
