// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServicePageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tagLink {
    color: var(--light);
    font-size: 0.9em;
    background-color: var(--dark);
    padding: 4px 12px;
    border-radius: 25px;
}

.tagLink:hover:not(.tagLink.lg) {
    transform: scale(1.03);
}

.tagLink.lg {
    font-size: 1em;
    padding: 6px 16px;
}

.ServicePageHeader .tagLinkHeader {
    font-size: 0.9em;
    font-weight: 600;
}

@media (max-width: 1300px) {
    .ServicePageHeader .tagLink {
        font-size: 0.85em;
    }
    .ServicePageHeader .tagLinkHeader {
        font-size: 0.85em;
    }
}

@media (max-width: 650px) {
    .ServicePageHeader .tagLink, .ServicePageHeader .tagLinkHeader{
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/ServicePageHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,6BAA6B;IAC7B,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".ServicePageHeader {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.tagLink {\n    color: var(--light);\n    font-size: 0.9em;\n    background-color: var(--dark);\n    padding: 4px 12px;\n    border-radius: 25px;\n}\n\n.tagLink:hover:not(.tagLink.lg) {\n    transform: scale(1.03);\n}\n\n.tagLink.lg {\n    font-size: 1em;\n    padding: 6px 16px;\n}\n\n.ServicePageHeader .tagLinkHeader {\n    font-size: 0.9em;\n    font-weight: 600;\n}\n\n@media (max-width: 1300px) {\n    .ServicePageHeader .tagLink {\n        font-size: 0.85em;\n    }\n    .ServicePageHeader .tagLinkHeader {\n        font-size: 0.85em;\n    }\n}\n\n@media (max-width: 650px) {\n    .ServicePageHeader .tagLink, .ServicePageHeader .tagLinkHeader{\n        display: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
