// util functions
import { useState, useLayoutEffect } from "react";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function loopingSlice(arr, page, count) {
  /*
    This function is used to loop over an array - 'arr', removing 'chunks' of contiguous elements of length 'count'.
    the benefit to this function is that it can be used to loop over an array in chunks, and when the end of the array 
    is reached, it will still return a fixed size 'chunk', by adding the elements from the start of the array to that chunk.

    The 'page' parameter defines which 'chunk' of the array to remove from. 
    The 'count' parameter defines how many items to return in a chunk.
    for example - if the array is [2,3,4,5,6,7,8], the page value is 0, and the  it will return arr.slice(0, 0)
    */

  const max = arr.length;

  if (((page * count) % max) + count > max) {
    // if the 'chunk' exceeds the length of the array
    // take the ending elements of the array, and concatenate the rest of the 'chunk' with elements from the start of the array.
    return arr.slice((page * count) % max, ((page * count) % max) + count).concat(arr.slice(0, ((page * count) % max) + count - max));
  }
  // otherwise, return the chunk.  Modulo forces the chunk to always remain within the bounds of the array (+/- the length of the 'chunk')
  return arr.slice((page * count) % max, ((page * count) % max) + count);
}

// function objectToArray(obj) {
//   let arr = Object.keys(obj).map((key) => {
//     return obj[key];
//   });
//   arr.pop(); //remove length value that comes from converting obj to array
//   arr.pop(); //remove reference to self that comes from converting obj to array
//   return arr;
// }

function compareDates(a, b, ascending = true) {
  a = new Date(a);
  b = new Date(b);
  let sortValues = [1, -1];
  if (!ascending) {
    sortValues = [-1, 1];
  }

  if (a.getTime() < b.getTime()) {
    return sortValues[0];
  }
  if (a.getTime() > b.getTime()) {
    return sortValues[1];
  }
  if (a.getTime() === b.getTime()) {
    return 0;
  }
}

function formatDate(date) {
  const d = new Date(date);
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return `${days[d.getDay()]}, ${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

function getDonorsAtIndex(idx, limit, donors) {
  if (donors) {
    const benefactorCount = donors?.benefactor?.length;
    const patronCount = donors?.patron?.length;
    const friendCount = donors?.friend?.length;

    if (idx <= benefactorCount) {
      //return benefactors
      return {
        donorType: "benefactor",
        donors: donors?.benefactor,
      };
    }
    if (idx <= benefactorCount + patronCount - limit) {
      // return patrons
      return {
        donorType: "patron",
        donors: donors?.patron?.slice(idx - benefactorCount - 1, idx + limit - benefactorCount),
      };
    }
    if (idx <= benefactorCount + (patronCount - limit) + (friendCount - limit)) {
      // return friends
      return {
        donorType: "friend",
        donors: donors?.friend?.slice(idx - (benefactorCount + patronCount - limit) - 1, idx + limit - (benefactorCount + patronCount - limit)),
      };
    } else {
      // return benefactors
      return {
        donorType: "benefactor",
        donors: donors?.benefactor,
      };
    }
  }

  return [];
}

export { loopingSlice, compareDates, formatDate, useWindowSize, getDonorsAtIndex };
