import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//TODO: eventually modify this so it can be closable

function Alert(props) {
  const [isOpen, setIsOpen] = useState(props.message);

  // reopen this message if the message changes
  useEffect(() => {
    setIsOpen(props.message);
  }, [props.message]);

  if (props.message) {
    return (
      <div className={isOpen ? "Alert rounded" : "hide"} style={props.message === "Thank you for your submission!" ? styles.greenAlert : styles.yellowAlert}>
        { props.href && (props.href[0] !== "/" ? <a href={props.href} target="_blank" rel="noopener noreferrer">{props.message}</a> : 
        <Link to={props.href || ""} >
          {props.message}
        </Link> )}
        {
          !props.href && <p style={props.message === "Thank you for your submission!" ? { color: "#155724" } : { color: "#9D812C" }}>{props.message}</p>
        }
        <i onClick={() => setIsOpen(false)} className="fa-solid fa-x" style={{ padding: "4px", color: "#9D812C", cursor: "pointer" }}></i>
      </div>
    );
  }
}

export default Alert;

const styles = {
  yellowAlert: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    width: "100%",
    border: "2px solid #FFEEBA",
    backgroundColor: "#FFF3CD",
    padding: "12px",
    marginBottom: "16px",
    maxWidth: "1100px",
  },
  greenAlert: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    border: "2px solid #C3E6CB",
    backgroundColor: "#D4EDDA",
    padding: "8px 16px",
    marginBottom: "16px",
    maxWidth: "1100px",
  },
};
