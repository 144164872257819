import React from "react";
import "../css/MobileLinkGroup.css";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
export default function MobileLinkGroup(props) {
  return (
    <div className="MobileLinkGroup">
      {props.links.map((link) => {
        return (
          <Link key={uuid()} to={link.path}>
            {link.name}
            <i className={"fa-solid " + link.icon}></i>
          </Link>
        );
      })}
    </div>
  );
}

MobileLinkGroup.defaultProps = {
  links: [
    { name: "Home", path: "/home", icon: "fa-house" },
    { name: "Home", path: "/home", icon: "fa-house" },
    { name: "Home", path: "/home", icon: "fa-house" },
  ],
};
