import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StaffSearchForm from "../../components/forms/StaffSearchForm";
import Loader from "../../components/Loader";
import Page from "../../components/page_components/Page";
import Pagination from "../../components/table/Pagination";
import Table from "../../components/table/Table";
import PageHeader from "../../components/page_components/PageHeader";
import * as StaffActions from "../../store/actions/staff";

export default function StaffLookup() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const { staff } = useSelector((state) => state.staff);
  const [search, setSearch] = useState("");
  const [department, setDepartment] = useState();
  const [headings, setHeadings] = useState([]);
  const [variables, setVariables] = useState([]);
  const [displayedStaff, setDisplayedStaff] = useState(null);
  const limit = 30;

  const { contactPage } = useSelector((state) => state.cms);
  let cms = contactPage;

  // set table headings based on cms
  useEffect(() => {
    if (cms) {
      let c1 = cms.lookupPageTableHeadings[0].col1;
      let c2 = cms.lookupPageTableHeadings[0].col2;
      let c3 = cms.lookupPageTableHeadings[0].col3;
      let c4 = cms.lookupPageTableHeadings[0].col4;
      let c5 = cms.lookupPageTableHeadings[0].col5;
      let c6 = cms.lookupPageTableHeadings[0].col6;
      let v1 = cms.lookupPageTableHeadings[1].col1;
      let v2 = cms.lookupPageTableHeadings[1].col2;
      let v3 = cms.lookupPageTableHeadings[1].col3;
      let v4 = cms.lookupPageTableHeadings[1].col4;
      let v5 = cms.lookupPageTableHeadings[1].col5;
      let v6 = cms.lookupPageTableHeadings[1].col6;
      setHeadings([c1, c2, c3, c4, c5, c6]);
      setVariables([v1, v2, v3, v4, v5, v6]);
    }
  }, [cms]);

  // Load data
  useEffect(() => {
    setLoading(true);
    dispatch(StaffActions.fetchStaffMembers());
  }, []);

  useEffect(() => {
    if (staff) {
      let tmp = [];
      if (!search && !department) {
        tmp = [...staff];
        tmp = tmp.sort((a, b) => a.personalInfo.lastName.localeCompare(b.personalInfo.lastName))
      }
      if (search) {
        tmp.push(...staff.filter((el) => el.personalInfo.firstName.toLowerCase().includes(search.toLowerCase())));
        tmp.push(...staff.filter((el) => el.personalInfo.lastName.toLowerCase().includes(search.toLowerCase())));
        tmp.push(...staff.filter((el) => el.personalInfo.title.toLowerCase().includes(search.toLowerCase())));
      }
      if (department) {
        tmp.push(...staff.filter((el) => el.department.id === department));
      }
      let sliced = tmp.slice(page * limit, (page + 1) * limit);

      let uniq = [...new Set(sliced)];

      setLoading(false);
      setDisplayedStaff(uniq);
    }
  }, [staff, search, department, page]);

  // if we reach end of pagination, flip back one page
  useEffect(() => {
    setLoading(false);
    if (displayedStaff && displayedStaff.length === 0) handlePageChange(-1);
  }, [displayedStaff]);

  // search submitted
  const handleFormSubmit = (search, department) => {
    setLoading(true);
    setSearch(search);
    setDepartment(department);
  };
  // const handleFormSubmit = (search, department) => {
  //   setLoading(true);
  //   setSearch(search);
  //   setDepartment(department);
  //   // dispatch(StaffActions.fetchStaffMembers());
  // };

  // this handles pagination
  const handlePageChange = (val) => {
    const min = 0;
    const newPage = page + val;
    if (newPage >= min) {
      setPage(newPage);
      // setLoading(true);
      // dispatch(StaffActions.fetchStaffMembers(limit, newPage * limit, search, department));
    }
  };

  // conditionally render the table if we have data
  const renderTable = () => {
    if (loading) {
      return <Loader />;
    } else if (displayedStaff && displayedStaff.length !== 0) {
      return <Table headings={headings} data={displayedStaff} variables={variables} />;
    } else {
      return <p>No results...</p>;
    }
  };

  if (!cms) return null;

  return (
    <Page sidebarType="contact">
      <PageHeader text={cms.lookupPageTitle} />
      <StaffSearchForm
        handleSubmit={handleFormSubmit}
        inputPlaceholder={cms.lookupPageSearchPlaceholder}
        deptPlaceholder={cms.lookupPageDepartmentFilterPlaceholder}
        buttonText={cms.lookupPageSubmitButton.buttonText}
      />
      {renderTable()}
      { staff && staff.length > limit && <Pagination page={page} onNext={() => handlePageChange(1)} onBack={() => handlePageChange(-1)} /> }
    </Page>
  );
}
