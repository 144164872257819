import React from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import App from "./App";
import { Provider } from "react-redux";
import staffReducer from "./store/reducers/staff";
import accommodationReducer from "./store/reducers/accommodations";
// import serviceReducer from "./store/reducers/services";
import articleReducer from "./store/reducers/articles";
import boardMemberReducer from "./store/reducers/board-members";
import testimonialReducer from "./store/reducers/testimonials";
import donorReducer from "./store/reducers/donors";
import eventReducer from "./store/reducers/events";
import cmsReducer from "./store/reducers/cms";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// redirect to https on production env
if (window.location.hostname !== "localhost" && window.location.protocol !== "https:") {
  window.location = "https:" + window.location.href.substring(window.location.protocol.length);
  // return; // to stop app from mounting
}

// Redux Store Setup
const rootReducer = combineReducers({
  staff: staffReducer,
  accommodation: accommodationReducer,
  // service: serviceReducer,
  article: articleReducer,
  testimonial: testimonialReducer,
  donor: donorReducer,
  event: eventReducer,
  boardMember: boardMemberReducer,
  cms: cmsReducer,
});
const store = configureStore({ reducer: rootReducer });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
