import React, { useEffect, useState } from "react";
import Gallery from "../Gallery";
import Accordion from "../Accordion";
import RichText from "../RichText";
import Tags from "../Tags";
import { useSelector } from "react-redux";
import LgServiceCardGrid from "../LgServiceCardGrid";
import TextWithImage from "../TextWithImage";
import CardGroup from "../cards/CardGroup";
import Button from "../Button";
import StaffContactBanner from "../StaffContactBanner";
import QuoteCard from "../cards/QuoteCard";

const fullPageWidth = ["ui.accordion", "ui.staff-contact-banner"];

function Section({ data }) {
  const { sections } = useSelector((state) => state.cms);
  const [display, setDisplay] = useState();

  useEffect(() => {
    if (sections && data) setDisplay(sections.filter((el) => el.id === data.id)[0]);
  }, [sections, data]);

  const renderContent = () => {
    const { __component, photos, item, items, text, image, buttonText, linkUrl, centered } = display.content[0];

    switch (__component) {
      case "ui.gallery":
        return <Gallery images={photos} />;
      case "ui.accordion":
        return <Accordion items={item} />;
      case "ui.tags":
        return <Tags items={items} />;
      case "ui.card-grid":
        return <LgServiceCardGrid items={items} />;
      case "ui.text-image":
        return <TextWithImage text={text} image={image} />;
      case "ui.rich-text":
        return <RichText text={text} style={centered ? { textAlign: "center" } : null} />;
      case "ui.button":
        return (
          <Button buttonType="link" href={linkUrl}>
            {buttonText}
          </Button>
        );
      case "ui.card-group":
        return (
          <CardGroup
            cards={items.map((item) => {
              return { header: item.title, content: item.body, img: item.image.url, altText: item.image.alternativeText };
            })}
          />
        );
      case "ui.staff-contact-banner":
        return <StaffContactBanner />;

      case "ui.quote":
        return <QuoteCard />;
      default:
        return null;
    }
  };
  if (!display) return null;

  return (
    <>
      <div className="container">
        <RichText text={display.before} />
      </div>
      <div className={fullPageWidth.includes(display.content[0].__component) ? "fullPageWidth" : "container"}>{renderContent()}</div>
      <div className="container">
        <RichText text={display.after} />
      </div>
    </>
  );
}

export default Section;
