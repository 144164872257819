import React from "react";
import StaffIdCard from "./StaffIdCard";
import "../../css/ContactCard.css";

export default function ContactCard({ extraStyles, data }) {
  return (
    <div className="ContactCard rounded" style={extraStyles}>
      <StaffIdCard data={data} />
      <div className="contactInfo" style={{ display: "flex", gap: "8px", marginTop: "8px", flexDirection: "column", width: "100%" }}>
        <div style={{ display: "flex", marginTop: "16px" }}>
          <h3 style={{ flexBasis: "50%", margin: "0", textAlign: "end", marginRight: "8px" }}>
            <i className="fa-solid fa-phone" style={{ marginRight: "8px", fontSize: "0.8em" }}></i>Phone
          </h3>
          <h3 style={{ flexBasis: "50%", margin: "0" }}>
            <i className="fa-solid fa-envelope" style={{ marginRight: "8px", fontSize: "0.8em" }}></i>Email
          </h3>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ textAlign: "end", marginRight: "8px" }} className="contactInfoText">
            {data.phone}
          </p>
          <p className="contactInfoText">{data.email}</p>
        </div>
      </div>
    </div>
  );
}
