import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BookTourCTACard from "../../components/cards/BookTourCTACard";
import Page from "../../components/page_components/Page";
import ServiceCard from "../../components/cards/ServiceCard";
import ImageLightbox from "../../components/ImageLightbox";
import * as AccommodationActions from "../../store/actions/accommodations";
import RichText from "../../components/RichText";
import Alert from "../../components/Alert";
import Table from "../../components/table/Table";
import QuoteCard from "../../components/cards/QuoteCard";
import PageHeader from "../../components/page_components/PageHeader";

export default function AccomodationsDetail() {
  const { slug } = useParams();
  const { accommodations } = useSelector((state) => state.accommodation);
  const [data, setData] = useState();
  const [headings, setHeadings] = useState();
  const [variables, setVariables] = useState();

  // reset page & load data
  useEffect(() => {
    setData(null);
    setHeadings(null);
    setVariables(null);
  }, [slug]);

  // select the one to display based on slug
  useEffect(() => {
    if (accommodations) {
      let tmp = accommodations.filter((el) => el.slug === slug);
      if (tmp) setData(tmp[0]);
    }
  }, [accommodations, slug]);

  // build pricing sctable
  useEffect(() => {
    if (data && data.prices && data.prices.length !== 0) {
      let hds = [];
      let vrs = [];
      if (data.prices[0].col1) {
        hds.push(data.prices[0].col1);
        vrs.push("col1");
      }
      if (data.prices[0].col2) {
        hds.push(data.prices[0].col2);
        vrs.push("col2");
      }
      if (data.prices[0].col3) {
        hds.push(data.prices[0].col3);
        vrs.push("col3");
      }
      if (data.prices[0].col4) {
        hds.push(data.prices[0].col4);
        vrs.push("col4");
      }
      if (data.prices[0].col5) {
        hds.push(data.prices[0].col5);
        vrs.push("col5");
      }
      setHeadings(hds);
      setVariables(vrs);
    }
  }, [data]);

  if (!data) return <Page sidebarType="accommodations" />;
  return (
    <Page sidebarType="accommodations">
       <PageHeader text={data.title} />
      <Alert message="Please note - There is a long wait time to get into any of our facilities.  The application process can be extensive and wait times vary depending on the individual circumstances.  Please read our application process page for more details." />
      <ImageLightbox photos={data.photos} />
      <div className="container">
        {data.amenities && (
          <div>
            <h2 className="eb" style={{ margin: "16px 0" }}>
              Amenities
            </h2>
            <div className="amenities">
              {data.amenities.map((el) => (
                <div key={el.text} className="amenity">
                  <img src="/icons/check.png" />
                  <p>{el.text}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {data.services && (
          <div>
            <h2 className="eb">Services</h2>
            <div className="serviceCards">
              {console.log('here', data.services)}
              {data.services.map((el) => (
                <ServiceCard key={el.id} text={el.title} icon={el.icon && el.icon.url} slug={el.slug} />
              ))}
            </div>
          </div>
        )}
        {data.eligibilityCriteria && (
          <div style={{ marginBottom: "32px" }}>
            <h2>Eligibility Criteria</h2>
            <RichText text={data.eligibilityCriteria} />
          </div>
        )}
        {data.description && (
          <div style={{ marginBottom: "32px" }}>
            <h2 className="eb">More Information</h2>
            <RichText text={data.description} />
          </div>
        )}
      </div>
      <BookTourCTACard />
      {(data.pricesAlternative || data.prices) && (
        <div>
          {data.pricesAlternative && (
            <div className="altPricing fullPageWidth">
              <div>
                <RichText text={data.pricesAlternative} />
              </div>
            </div>
          )}
          {headings && variables && data.prices && (
            <div className="container">
              <h2 className="eb">Pricing</h2>
              <Table headings={headings} data={data.prices.slice(1)} variables={variables} />
            </div>
          )}
        </div>
      )}

      <QuoteCard />
    </Page>
  );
}
