import React from "react";
import Page from "../../components/page_components/Page";
import CardGroup from "../../components/cards/CardGroup";
import DonorBoard from "../../components/donor_board/DonorBoard";
import { useSelector } from "react-redux";

export default function DonorPage() {
  const { donors } = useSelector((state) => state.donor);
  const { getInvolvedPage } = useSelector((state) => state.cms);
  let cms = getInvolvedPage;
  if (!cms) return null;

  const {
    donorsPageDonorsGroup1MainText,
    donorsPageDonorsGroup1SubText,
    donorsPageDonorsGroup2MainText,
    donorsPageDonorsGroup2SubText,
    donorsPageDonorsGroup3MainText,
    donorsPageDonorsGroup3SubText,
    donorsPageDonorsGroup4MainText,
    donorsPageDonorsGroup4SubText,
    donorsPageCardsHeader,
  } = cms;

  return (
    <Page sidebarType="getInvolved">
      <div className="container">
        <Leaves />

        {/* RECENT DONORS */}
        {donors?.recent?.length > 0 && (
          <div style={{ marginBottom: "64px" }}>
            <h2 className="eb txtCenter" style={{ marginBottom: "4px" }}>
              {donorsPageDonorsGroup1MainText}
            </h2>
            <p
              className="txtCenter"
              style={{
                maxWidth: "400px",
                margin: "0 auto 32px auto",
                fontSize: "1rem",
              }}
            >
              {donorsPageDonorsGroup1SubText}
            </p>
            <DonorBoard donors={donors?.recent} category="recent" />
          </div>
        )}

        {/* BENEFACTORS */}
        <div style={{ marginTop: "64px", marginBottom: "64px" }}>
          <h2 className="eb txtCenter" style={{ marginBottom: "4px" }}>
            {donorsPageDonorsGroup2MainText}
          </h2>
          <p
            className="txtCenter"
            style={{
              maxWidth: "400px",
              margin: "0 auto 32px auto",
              fontSize: "1rem",
            }}
          >
            {donorsPageDonorsGroup2SubText}
          </p>
          <DonorBoard donors={donors?.benefactor} category="benefactor" />
        </div>
        <div style={{ marginTop: "64px", marginBottom: "64px" }}>
          <h2 className="eb txtCenter" style={{ marginBottom: "4px" }}>
            {donorsPageDonorsGroup3MainText}
          </h2>
          <p
            className="txtCenter"
            style={{
              maxWidth: "400px",
              margin: "0 auto 32px auto",
              fontSize: "1rem",
            }}
          >
            {donorsPageDonorsGroup3SubText}
          </p>
          <DonorBoard donors={donors?.patron} category="patron" />
        </div>
        <div style={{ marginTop: "64px", marginBottom: "64px" }}>
          <h2 className="eb txtCenter" style={{ marginBottom: "4px" }}>
            {donorsPageDonorsGroup4MainText}
          </h2>
          <p
            className="txtCenter"
            style={{
              maxWidth: "400px",
              margin: "0 auto 32px auto",
              fontSize: "1rem",
            }}
          >
            {donorsPageDonorsGroup4SubText}
          </p>
          <DonorBoard donors={donors?.friend} category="friend" />
        </div>
        <h2 className="eb">{donorsPageCardsHeader}</h2>
        <CardGroup
          condenseOnMobile={true}
          cards={cms.getInvolvedCards.map((el) => {
            return {
              header: el.mainText,
              content: el.subText,
              img: el.image && el.image.url,
              buttonText: el.button && el.button[0] && el.button[0].buttonText,
              href: el.button && el.button[0] && el.button[0].linkUrl,
            };
          })}
        />
      </div>
    </Page>
  );
}

function Leaves() {
  const center = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(9, 1fr)",
        maxWidth: "750px",
        margin: "0 auto 48px auto",
      }}
    >
      <div style={center}>
        <img style={{ width: "90%", aspectRatio: "1/1 " }} src="/leaf_vector.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "70%", aspectRatio: "1/1 " }} src="/leaf_vector_green.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "90%", aspectRatio: "1/1 " }} src="/leaf_vector_yellow.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "70%", aspectRatio: "1/1 " }} src="/leaf_vector.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "90%", aspectRatio: "1/1 " }} src="/leaf_vector_green.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "70%", aspectRatio: "1/1 " }} src="/leaf_vector_yellow.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "90%", aspectRatio: "1/1 " }} src="/leaf_vector.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "70%", aspectRatio: "1/1 " }} src="/leaf_vector_green.svg" alt="leaf" />
      </div>
      <div style={center}>
        <img style={{ width: "90%", aspectRatio: "1/1 " }} src="/leaf_vector_yellow.svg" alt="leaf" />
      </div>
    </div>
  );
}
