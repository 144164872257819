import React from 'react';
import './css/ActivityCard.css';

export default function ActivityCard({ day, date, month, activities }) {
    // Group activities by their time
    const groupedActivities = activities.reduce((acc, timeActivities) => {
        const time = timeActivities[0].time;
        if (!acc[time]) {
            acc[time] = [];
        }
        acc[time] = acc[time].concat(timeActivities);
        return acc;
    }, {});

    return (
        <div className='activity-card'>
            <div className='activity-card-header'>
                <div className='activity-card-date'>
                    <div className='day'>{day}</div>
                    <div className='date'>{date}</div>
                    <div className='month'>{month}</div>
                </div>
                {/* Use for possible birthday implementation
                {event && (
                    <div className='activity-card-event'>
                        <div className='event-icon'>
                            <img className='birthday-event' src='/birthday_event.svg' alt='Birthday Event' />
                        </div>
                        <div className='event'>{event}</div>
                    </div>
                )}
                */}
            </div>
            <hr className='divider' />
            <div className='activity-section'>
                {Object.keys(groupedActivities).map((time, index) => (
                    <div key={index} className="activity-block">
                        <div className='activity-time'>{time}</div>
                        <div className='activities'>
                            {groupedActivities[time].map((activity, activityIndex) => (
                                <div className='activity' key={activityIndex}>
                                    <div className='activity-name'>{activity.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
