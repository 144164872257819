import React from "react";
import Page from "../components/page_components/Page";

function ErrorPage() {
  return (
    <Page>
      <h1>404 Error</h1>
    </Page>
  );
}

export default ErrorPage;
