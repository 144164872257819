import axios from "axios";

export const FETCH_TESTIMONIALS = "FETCH_TESTIMONIALS";

export const fetchTestimonials = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/testamonials`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_TESTIMONIALS,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};
