import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../css/ServicePageHeader.css";
import { v4 as uuid } from "uuid";
import PageHeader from "./page_components/PageHeader";
import { Link } from "react-router-dom";

export default function ServicePageHeader({ header, slug }) {
  const [service, setService] = useState();
  const { servicePages, servicesPage } = useSelector((state) => state.cms);

  useEffect(() => {
    if (servicePages) {
      let tmp = servicePages.filter((el) => el.slug === "/services/" + slug)[0];
      setService(tmp);
    }
  }, [servicePages, slug]);

  return (
    <div className="ServicePageHeader container" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <PageHeader text={header} />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "4px" }}>
        <span className="tagLinkHeader">{servicesPage.accommodationsLinkText}</span>
        <div style={{ display: "flex", gap: "4px" }}>
          {service &&
            service.accommodations &&
            service.accommodations.map((el) => {
              return (
                <Link key={uuid()} className="eb tagLink" to={`/accommodations/${el.slug}`}>
                  {el.title === "Long-Term Care" ? "Long-Term Care" : el.title.split(" ")[0]}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}
