import React from "react";
import Header from "./Header";
import Row from "./Row";
import MobileRow from "./MobileRow";
import "../../css/Table.css";
import { useWindowSize } from "../../util/util";
import {v4 as uuid} from 'uuid';

function Table({ headings, data, variables }) {
  // eslint-disable-next-line
  const [width, height] = useWindowSize();

  return (
    <>
      {width > 650 ? (
        // desktop table
        <table className="table">
          <thead>
            <Header headings={headings} />
          </thead>
          <tbody>
            {data.map((el) => (
              <Row key={uuid()} data={el} variables={variables} />
            ))}
          </tbody>
        </table>
      ) : (
        // mobile table
        <table className="table">
          <tbody>
            {data.map((el) => (
              <MobileRow key={uuid()} data={el} variables={variables} headings={headings}/>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}

export default Table;
