import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Page from "../components/page_components/Page";
import ServicePageHeader from "../components/ServicePageHeader";
import Section from "../components/page_components/Section";
import { v4 as uuid } from "uuid";
import "../css/ServicePageHeader.css";
import "../css/Accordion.css";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";

import Gallery from "../components/Gallery";
import Accordion from "../components/Accordion";
import RichText from "../components/RichText";
import Tags from "../components/Tags";

import LgServiceCardGrid from "../components/LgServiceCardGrid";
import TextWithImage from "../components/TextWithImage";
import CardGroup from "../components/cards/CardGroup";
import Button from "../components/Button";
import StaffContactBanner from "../components/StaffContactBanner";
import QuoteCard from "../components/cards/QuoteCard";
import ErrorPage from "./ErrorPage";

const getId = (params) => {
  let tmp = params["*"];

  // remove last character if it is a slash
  if (tmp[tmp.length - 1] === "/") {
    tmp = tmp.slice(0, -1);
  }

  return tmp;
};
const SubPage = () => {
  const { subPages } = useSelector((state) => state.cms);
  const params = useParams();
  let id = getId(params);

  if (!subPages) return null;
  let page = subPages?.find((page) => page.slug === id);
  if (!page) return <ErrorPage />;

  const renderPageContent = () => {
    if (page) {
      return (
        <>
          {page?.content?.map((el) => {
            return <SubPageSection key={uuid()} data={el} />;
          })}
        </>
      );
    }
  };
  return <Page>{renderPageContent()}</Page>;
};

export default SubPage;

const fullPageWidth = ["ui.accordion", "ui.staff-contact-banner"];

const SubPageSection = ({ data }) => {
  console.log(data);
  if (!data) return <Loader />;

  //   let display = sections.find((el) => el.id === data.id);
  //   console.log(display);
  //   useEffect(() => {
  //     if (sections && data) setDisplay(sections.filter((el) => el.id === data.id)[0]);
  //   }, [sections, data]);

  const renderContent = () => {
    const { __component, photos, item, items, text, image, buttonText, linkUrl, centered } = data;

    switch (__component) {
      case "ui.gallery":
        return <Gallery images={photos} />;
      case "ui.accordion":
        return <Accordion items={item} />;
      case "ui.tags":
        return <Tags items={items} />;
      case "ui.card-grid":
        return <LgServiceCardGrid items={items} />;
      case "ui.text-image":
        return <TextWithImage text={text} image={image} />;
      case "ui.rich-text":
        return <RichText text={text} style={centered ? { textAlign: "center" } : null} />;
      case "ui.button":
        return (
          <Button buttonType="link" href={linkUrl}>
            {buttonText}
          </Button>
        );
      case "ui.card-group":
        return (
          <CardGroup
            cards={items.map((item) => {
              return { header: item.title, content: item.body, img: item.image.url, altText: item.image.alternativeText };
            })}
          />
        );
      case "ui.staff-contact-banner":
        return <StaffContactBanner />;

      case "ui.quote":
        return <QuoteCard />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={fullPageWidth.includes(data.__component) ? "fullPageWidth" : "container"}>{renderContent()}</div>
    </>
  );
};
