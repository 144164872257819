// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardGroup {
  max-width: 1100px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  margin-bottom: 32px;
  margin: 32px auto;
}

@media (min-width: 570px) {
  .CardGroup {
    grid-template-columns: repeat(2, 1fr);
  }
  /* if there are an odd number of cards, make the last card span 2 columns */
  .CardGroup :last-child:not(:nth-child(even)) {
    grid-column: span 2;
  }
}

@media (min-width: 1100px) {
  .CardGroup {
    grid-template-columns: repeat(3, 1fr);
  }
  .CardGroup :last-child:not(:nth-child(even)) {
    grid-column: span 1;
  }
}

@media (max-width: 570px) {
  .CardGroup.condense {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/CardGroup.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,cAAc;EACd,0BAA0B;EAC1B,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;IACE,qCAAqC;EACvC;EACA,2EAA2E;EAC3E;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;EACA;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".CardGroup {\n  max-width: 1100px;\n  display: grid;\n  grid-gap: 16px;\n  grid-template-columns: 1fr;\n  margin-bottom: 32px;\n  margin: 32px auto;\n}\n\n@media (min-width: 570px) {\n  .CardGroup {\n    grid-template-columns: repeat(2, 1fr);\n  }\n  /* if there are an odd number of cards, make the last card span 2 columns */\n  .CardGroup :last-child:not(:nth-child(even)) {\n    grid-column: span 2;\n  }\n}\n\n@media (min-width: 1100px) {\n  .CardGroup {\n    grid-template-columns: repeat(3, 1fr);\n  }\n  .CardGroup :last-child:not(:nth-child(even)) {\n    grid-column: span 1;\n  }\n}\n\n@media (max-width: 570px) {\n  .CardGroup.condense {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
