import React from "react";
import "../../css/BoardMemberCard.css";

export default function BoardMemberCard({name, title, activeSince}) {
  return (
    <div className="BoardMemberCard rounded" style={{maxWidth: '1100px'}}>
      <div>
        <h3>{name}</h3>
        <p className="title">{title}</p>
      </div>
      <p>Since {activeSince}</p>
    </div>
  );
}
