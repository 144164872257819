import React from "react";
import "../../css/DonorBoard.css";
// import { formatDate } from "../../util/util";

export default function Donor({ name, category }) {

  return (
    <div className={"Donor " + category}>
      <p style={{ flexGrow: "1", textAlign: "center", fontWeight: "600" }}>{name}</p>
      {/* <p style={{ flexGrow: "1", textAlign: "center" }}>{formatDate(props.date)}</p> */}
      {/* <span className='monthAndDay'>{monthDay}</span> */}
      {/* <p style={{flexGrow: '1', textAlign: 'center'}}>${props.amount}</p> */}
      {/* <p>{props.message}</p> */}
    </div>
  );
}

Donor.defaultProps = {
  name: "John Doe",
  amount: 100,
};
