import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../css/RichText.css";

function RichText({ text, snip, style, className }) {
  if (!text) return null;

  return (
    <div className={`RichText ${className}`} style={style}>
      <ReactMarkdown children={snip ? text.slice(0, snip) : text} remarkPlugins={[remarkGfm]} />
    </div>
  );
}

export default RichText;
