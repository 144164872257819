import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../css/MobileNavbar.css";

function AccordionNavLink(props) {
  return (
    <>
      {/*inline style used to reduce CSS clutter*/}
      <button tabIndex={props.tabIndex} className="accordion-header" aria-label={props.children + " submenu"} aria-expanded={props.isOpen}>
        <span>{props.children}</span>
        <i className={"fa-solid fa-caret-" + (props.isOpen ? "up" : "down")}></i>
      </button>
      <div className={props.isOpen ? "accordion-options" : "accordion-options hidden"}>
        <ul>
          {props.sublinks.map((sublink) => {
            return (
              <li key={sublink.text}>
                <Link to={sublink.slug}>{sublink.text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default AccordionNavLink;
