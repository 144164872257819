import React, { useEffect } from "react";
import Page from "../components/page_components/Page";
import "../css/Homepage.css";
import Button from "../components/Button";
import Alert from "../components/Alert";
import CardGroup from "../components/cards/CardGroup";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { formatDate } from "../util/util";
import DonorBoard from "../components/donor_board/DonorBoard";
import { Link, useNavigate } from "react-router-dom";
import * as CmsActions from "../store/actions/cms";

function Home() {
  const navigate = useNavigate();
  const { accommodations } = useSelector((state) => state.accommodation);
  const { servicePages, landingPage } = useSelector((state) => state.cms);
  const { articles } = useSelector((state) => state.article);
  const { donors } = useSelector((state) => state.donor);

  let cms = landingPage;
  if (!cms) return null;

  console.log(cms);

  const pinnedArticles = articles && articles.filter((el) => el.isPinned);

  return (
    <Page>
      <div className="Homepage">
        <Hero data={cms && cms.heroSection} />
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          {/* main homepage content, below hero */}

          {cms && cms.bannerAlert && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert message={cms?.bannerAlert?.buttonText} href={cms.bannerAlert?.pdf?.url} />
            </div>
          )}

          <BannerCard data={cms && cms.callToAction1} />
          {cms && cms.showAccommodations && accommodations && (
            <>
              <h2 className="eb">Accommodations</h2>

              <div className="accommodations">
                {accommodations.map((el, i) => (
                  <AccommodationsCard
                    key={uuid()}
                    img={el.photos && el.photos.length > 0 && el.photos[0].url}
                    header={el.title}
                    text={el.cardSummary}
                    href={`/accommodations/${el.slug}`}
                    leftAlign={i % 2 ? false : true}
                  />
                ))}
              </div>
            </>
          )}

          {cms && cms.showServices && servicePages && servicePages.length > 0 && (
            <>
              <h2 className="eb">Services</h2>
              <CardGroup
                cards={servicePages.map((el) => {
                  if (!el.cardView) return null;
                  const { title, body, image } = el.cardView;
                  return {
                    img: image && image.url,
                    altText: image && image.alternativeText,
                    header: title,
                    content: body,
                    buttonText: "More Info",
                    href: el.slug,
                  };
                })}
              />
            </>
          )}
          {cms && cms.showFeaturedNews && articles && (
            <>
              {pinnedArticles.length > 0 && <h2 className="eb">News</h2>}
              <div className="newsGrid">{pinnedArticles.length > 0 && pinnedArticles.map((el) => <SmallNewsCard key={uuid()} title={el.title} date={el.date} href={`/news/${el.slug}`} />)}</div>
            </>
          )}
          {cms && cms.historySection && (
            <div className="history">
              <img src={cms.historySection.image && cms.historySection.image.url} alt="Old Photo of Heidehof" />
              <div>
                <h2 className="eb" style={{ margin: "0" }}>
                  {cms.historySection.mainText}
                </h2>
                <p style={{ fontSize: "1.1rem" }}>{cms.historySection.subText}</p>

                {cms.historySection.button && (
                  <Button
                    light={true}
                    buttonType="icon"
                    iconLeft={cms.historySection.button[0].iconLeft && cms.historySection.button[0].iconLeft.url}
                    iconRight={cms.historySection.button[0].iconRight && cms.historySection.button[0].iconRight.url}
                    onClick={() => navigate(cms.historySection.button[0].linkUrl)}
                  >
                    {cms.historySection.button[0].buttonText}
                  </Button>
                )}
              </div>
            </div>
          )}
          {cms && cms.callToAction2 && <h2 className="eb">Get Involved</h2>}
          <div
            style={{
              display: "flex",
              gap: "8px",
              flexWrap: "wrap",
              marginBottom: "16px",
            }}
          >
            {cms &&
              cms.getInvolvedButtons &&
              cms.getInvolvedButtons.map((el) => {
                return (
                  <Button key={uuid()} light={true} buttonType="icon" iconLeft={el.iconLeft && el.iconLeft.url} iconRight={el.iconRight && el.iconRight.url} onClick={() => navigate(el.linkUrl)}>
                    {el.buttonText}
                  </Button>
                );
              })}
          </div>
          <BannerCard data={cms && cms.callToAction2} />
          {cms && cms.showDonors && donors && donors?.recent?.length > 0 && (
            <>
              <h2 className="txtCenter eb">Recent Donors</h2>
              <DonorBoard donors={donors?.recent} />
              <Button buttonType="link" href="/getinvolved/donors" extraStyles={{ margin: "32px auto", marginBottom: "0" }}>
                View All Donors
              </Button>
            </>
          )}
          {cms?.resources && (
            <div
              style={{
                paddingTop: "48px",
                marginBottom: "-16px",
                textAlign: "end",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "8px", alignItems: "flex-start", flexDirection: "column", maxWidth: "400px" }}>
                <a href={cms.resources.left_link} target={cms.resources.left_link[0] === "/" ? "" : "_blank"} style={{ marginTop: "16px", borderRadius: "0" }}>
                  {cms.resources.left_image && (
                    <img
                      src={cms.resources.left_image.url}
                      alt="Ontario Builds Graphic - Heidehof Retrofit HVAC and Sprinkler Installation"
                      style={{ width: "350px", borderRadius: "0", border: "1px solid #ccc" }}
                    />
                  )}
                </a>

                <p style={{ textAlign: "left", fontSize: "14px", marginTop: "0px" }}>{cms.resources.left_text}</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                  style={{
                    fontSize: "20px",
                    marginBottom: "4px",
                    fontWeight: "bold",
                  }}
                >
                  {cms.resources.right_heading}
                </p>
                {cms.resources.right_links?.map((link) => (
                  <a href={link.Link} target={link.Link[0] === "/" ? "" : "_blank"} style={{ textDecoration: "underline" }}>
                    {link.Text}
                  </a>
                ))}

                <a href={cms.resources.right_image_link} target={cms.resources.right_image_link[0] === "/" ? "" : "_blank"} style={{ marginTop: "16px", borderRadius: "0" }}>
                  {cms.resources.right_image && <img src={cms.resources.right_image.url} alt="Investing in Canada Logo" style={{ width: "250px", borderRadius: "0", border: "1px solid #ccc" }} />}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}

function SmallNewsCard(props) {
  return (
    <Link to={props.href}>
      <div className="SmallNewsCard">
        <h3>{props.title}</h3>
        <small className="eb">{formatDate(props.date)}</small>
      </div>
    </Link>
  );
}

function AccommodationsCard(props) {
  return (
    <div
      className="AccommodationsCard"
      style={{
        flexDirection: props.leftAlign ? "row" : "row-reverse",
        textAlign: props.leftAlign ? "start" : "end",
        margin: props.leftAlign ? "0 auto 16px 0" : "0 0 16px auto",
      }}
    >
      <div style={{ width: "50%", height: "auto" }}>
        <img
          style={{
            objectFit: "cover",
            boxShadow: "var(--box-shadow)",
          }}
          width="100%"
          height="100%"
          src={props.img}
          alt={props.header}
        />
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: props.leftAlign ? "start" : "end",
        }}
      >
        <h2 className="eb" style={{ margin: "0" }}>
          {props.header}
        </h2>
        <p>{props.text}</p>
        <Button buttonType="link" href={props.href}>
          More Info
        </Button>
      </div>
    </div>
  );
}

function BannerCard({ data }) {
  const navigate = useNavigate();
  if (!data) return null;
  return (
    <div
      className="banner rounded"
      style={{
        backgroundImage: `url(${data.image && data.image.url})`,
        boxShadow: "var(--box-shadow)",
      }}
    >
      <h2 className="eb">{data.mainText}</h2>
      <p>{data.subText}</p>
      <div style={{ display: "flex", gap: 20 }}>
        {data?.button.map((el) => {
          if (el.linkUrl[0] !== "/") {
            return (
              <a key={uuid()} className="Button rounded light" target="_blank" href={el.linkUrl} rel="noreferrer">
                {el.buttonText}
              </a>
            );
          }
          return (
            <Button key={uuid()} light={true} buttonType="icon" iconLeft={el.iconLeft && el.iconLeft.url} iconRight={el.iconRight && el.iconRight.url} onClick={() => navigate(el.linkUrl)}>
              {el.buttonText}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

function Hero({ data }) {
  const navigate = useNavigate();
  if (!data) return null;
  return (
    <div
      className="hero fullPageWidth heroImage"
      style={{
        backgroundImage: `url(${data.image && data.image.url})`,
        boxShadow: "var(--box-shadow)",
      }}
    >
      <h1 className="cinzel" style={{ marginTop: "56px" }}>
        {data.mainText}
      </h1>
      <p>{data.subText}</p>
      {data.button && (
        <Button
          light={true}
          buttonType="icon"
          iconLeft={data.button[0].iconLeft && data.button[0].iconLeft.url}
          iconRight={data.button[0].iconRight && data.button[0].iconRight.url}
          onClick={() => navigate(data.button[0].linkUrl)}
        >
          {data.button[0].buttonText}
        </Button>
      )}
    </div>
  );
}

export default Home;
