import React, { useState, useEffect } from "react";
import { formatDate } from "./util/util";

export const Clock = () => {
  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return (
    <div className="time">
      <h2> {date.toLocaleTimeString().toLocaleLowerCase()}</h2>
      <p> {formatDate(date)}</p>
    </div>
  );
};

export default Clock;

// import React, { useEffect, useState } from "react";
// import { formatDate } from "./util/util";

// const Clock = () => {
//   const [currentTime, setCurrentTime] = useState();
//   const [currentDate, setCurrentDate] = useState();

//   // set time
//   useEffect(() => {
//     const timer = setInterval(() => {
//       const d = new Date();
//       let hr = d.getHours();
//       const min = d.getMinutes();
//       let ampm = "am";
//       if (hr > 12) {
//         hr -= 12;
//         ampm = "pm";
//       }
//       setCurrentTime(`${hr}:${pad(min, 2)}${ampm}`);
//       setCurrentDate(formatDate(d));
//     }, 1000);
//     return () => clearInterval(timer);
//   });

//   return (
//     <div className="time">
//       <h2>{currentTime}</h2>
//       <p>{currentDate}</p>
//     </div>
//   );
// };

// export default Clock;
// function pad(num, size) {
//   num = num.toString();
//   while (num.length < size) num = "0" + num;
//   return num;
// }
