import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import Button from "../Button";

function StaffSearchForm({ handleSubmit, inputPlaceholder, deptPlaceholder, buttonText }) {
  const [searchValue, setSearchValue] = useState("");
  const [searchDeptId, setSearchDeptId] = useState("");
  const { departments } = useSelector((state) => state.staff);

  // search value is changed
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleDeptChange = (e) => {
    setSearchDeptId(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(searchValue, searchDeptId);
  };

  return (
    <form style={{ display: "flex", alignItems: "center", gap: "8px" }} onSubmit={onSubmit}>
      <input type="text" className="textInput" style={{ maxWidth: "400px", margin: "0" }} placeholder={inputPlaceholder} value={searchValue} onChange={handleSearchChange} />
      <select name="depts" className="textInput" style={{ maxWidth: "400px", margin: "0" }} id="depts" onChange={handleDeptChange} value={searchDeptId}>
        <option value="">{deptPlaceholder}</option>
        {departments &&
          departments.map((el) => {
            return (
              <option key={uuid()} value={el.id}>
                {el.title}
              </option>
            );
          })}
      </select>
      <Button buttonType="submit" extraStyles={{ height: "40px" }}>
        {buttonText}
      </Button>
    </form>
  );
}

export default StaffSearchForm;
