// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card {
  display: flex;
  margin: 5px;
  flex-direction: column;
  filter: var(--shadow);
}

.Card .text-area {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  background-color: var(--light);
  padding: 24px 16px;
  border-radius: 0 0 8px 8px;
}

.Card img {
  width: 100%;
  border-radius: 8px 8px 0 0;
  height: 250px;
  object-fit: cover;
}

.Card h3 {
  margin: 0;
}

.Card p {
  margin: 0;
  max-width: 300px;
}

.Card i {
  color: var(--dark);
  width: fit-content;
}

.Card .text-area div {
  display: flex;
  gap: 8px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/Card.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,8BAA8B;EAC9B,8BAA8B;EAC9B,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".Card {\n  display: flex;\n  margin: 5px;\n  flex-direction: column;\n  filter: var(--shadow);\n}\n\n.Card .text-area {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  justify-content: space-between;\n  background-color: var(--light);\n  padding: 24px 16px;\n  border-radius: 0 0 8px 8px;\n}\n\n.Card img {\n  width: 100%;\n  border-radius: 8px 8px 0 0;\n  height: 250px;\n  object-fit: cover;\n}\n\n.Card h3 {\n  margin: 0;\n}\n\n.Card p {\n  margin: 0;\n  max-width: 300px;\n}\n\n.Card i {\n  color: var(--dark);\n  width: fit-content;\n}\n\n.Card .text-area div {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
