import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Page from "../../components/page_components/Page";
import ServicePageHeader from "../../components/ServicePageHeader";
import Section from "../../components/page_components/Section";
import { v4 as uuid } from "uuid";
import "../../css/ServicePageHeader.css";
import "../../css/Accordion.css";
import { useParams } from "react-router-dom";

function ServicesDetail() {
  const { servicesCms } = useSelector((state) => state.cms);
  const { slug } = useParams();
  const renderPageContent = () => {
    if (servicesCms) {
      return (
        <>
          <ServicePageHeader header={servicesCms.heading} slug={slug} />
          {servicesCms &&
            servicesCms.sections &&
            servicesCms.sections.map((el) => {
              return <Section key={uuid()} data={el.section} />;
            })}
        </>
      );
    }
  };
  return <Page sidebarType="services">{renderPageContent()}</Page>;
}

export default ServicesDetail;
