import React from "react";
import { Link } from "react-router-dom";
import "../../css/News.css";

export default function NewsCard(props) {
	let snip = 250;
	let summary;
	if (props.contentSnippet) {
		if (props.contentSnippet.length > snip) {
			summary = props.contentSnippet.slice(0, snip) + "...";
		} else {
			summary = props.contentSnippet;
		}
	}

	return (
		<Link to={`/news/${props.slug}`} className="NewsCard">
			{/* use 2 column grid if image prop is shown */}
			<div className={props.featureImg && "twoColumns"}>
				<div>
					<h2 className="eb" style={{ marginTop: "0" }}>
						{props.title}
					</h2>

					<p>{summary}</p>
				</div>
				<div>
					{props.featureImg && (
						<img src={props.featureImg.url} alt={props.featureImg.alt} />
					)}
				</div>
			</div>

			<div style={styles.gridBottom}>
				<div>
					<p style={{ marginBottom: "0", marginTop: "0" }}>
						<strong>{props.date}</strong>
					</p>
				</div>

				{props.pinned && (
					<div style={styles.alignEnd}>
						<i
							className="fa-solid fa-thumbtack"
							style={{ marginRight: "12px", color: "var(--dark)" }}
						></i>
						<p style={styles.inlineText}>Pinned Article</p>
					</div>
				)}
			</div>
		</Link>
	);
}

const styles = {
	gridBottom: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr",
	},
	alignEnd: {
		display: "flex",
		justifyContent: "end",
		alignItems: "center",
	},
	inlineText: {
		display: "inline",
		marginBottom: "0",
		marginTop: "0",
		fontSize: "14px",
	},
};
