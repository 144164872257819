import React from "react";
import { NavLink } from "react-router-dom";

export default function IconLink(props) {
  const isActive = ({ isActive }) => (isActive ? "currentSidebarLink sidebarLink" : "sidebarLink");

  return (
    <NavLink to={props.to} className={isActive} style={{ width: "100%", display: "flex", padding: " 0.25rem 0.75rem", margin: "0.rem 0", alignItems: "center" }} tabIndex={props.tabIndex} end>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: "30px", aspectRatio: "1 / 1", marginRight: "0.5em" }}>
        <img src={props.icon} style={{ width: '20px' }} className="iconSvg"/>
      </div>
      <span style={{ fontSize: "1.15rem" }}>{props.linkName}</span>
    </NavLink>
  );
}
