import React from "react";
import { Link } from "react-router-dom";
import "../../css/ServiceCard.css";

function ServiceCard({ text, icon, slug }) {
  return (
    <Link to={slug || "/"} className="serviceCard">
      <img src={icon} />
      <p>{text}</p>
    </Link>
  );
}

export default ServiceCard;
