import React from "react";
import "../css/LgServiceCard.css";

export default function LgServiceCard(props) {
  return (
    <div className="LgServiceCard rounded">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>{props.header}</h3>
        <i className={"fa-solid " + props.icon}></i>
        {props.icon && <img src={props.icon.url} />}
      </div>
      <p>{props.content}</p>
    </div>
  );
}
