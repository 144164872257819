import { FETCH_DONORS } from "../actions/donors";

const initialState = {
  donors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DONORS:
      return {
        ...state,
        donors: action.data,
      };

    default:
      return state;
  }
};
