import React, { useEffect } from "react";

const Banner = ({ isVisible, setIsVisible, message }) => {
  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [isVisible]);

  if (isVisible) return <div className="formbanner">{message}</div>;
};

export default Banner;
