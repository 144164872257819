import React from "react";

function Header({ headings }) {
  return (
    <tr className="header">
      {headings.map((el, i) => {
        return (
          <th key={el} className="heading">
            <span>{el}</span>
          </th>
        );
      })}
    </tr>
  );
}

export default Header;
