// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StaffContactBanner {
    height: auto;
    padding: 16px;
    background-color: var(--light);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    filter: var(--shadow-muted);
    position: relative;
    z-index: 0;
}

.contactInfo {
    display: flex;
    gap: 16px;
}

.StaffContactBanner .info {
    margin: 4px;
}


  `, "",{"version":3,"sources":["webpack://./src/css/StaffContactBanner.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;IACnB,2BAA2B;IAC3B,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,WAAW;AACf","sourcesContent":[".StaffContactBanner {\n    height: auto;\n    padding: 16px;\n    background-color: var(--light);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 32px;\n    margin-bottom: 32px;\n    filter: var(--shadow-muted);\n    position: relative;\n    z-index: 0;\n}\n\n.contactInfo {\n    display: flex;\n    gap: 16px;\n}\n\n.StaffContactBanner .info {\n    margin: 4px;\n}\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
