import React from "react";
import Button from "../../components/Button";
import CardGroup from "../../components/cards/CardGroup";
import DonorBoard from "../../components/donor_board/DonorBoard";
import Page from "../../components/page_components/Page";
import PageHeader from "../../components/page_components/PageHeader";
import { useSelector } from "react-redux";

export default function GetInvolvedLanding() {
  const { donors } = useSelector((state) => state.donor);
  const { getInvolvedPage } = useSelector((state) => state.cms);
  let cms = getInvolvedPage;
  if (!cms) return null;

  return (
    <Page sidebarType="getInvolved">
      <div className="container">
        <PageHeader text={cms.landingPageTitle} />
      </div>
      <CardGroup
        condenseOnMobile={true}
        cards={cms.getInvolvedCards.map((el) => {
          return {
            header: el.mainText,
            content: el.subText,
            img: el.image && el.image.url,
            buttonText: el.button && el.button[0] && el.button[0].buttonText,
            href: el.button && el.button[0] && el.button[0].linkUrl,
          };
        })}
      />
      <div
        className="gridColumns"
        style={{
          gridTemplateRows: "250px",
          margin: "auto",
          marginBottom: "64px",
        }}
      >
        <div>
          <h2>{cms.landingPageCallToAction.mainText}</h2>
          <p>{cms.landingPageCallToAction.subText}</p>
          <Button buttonType="link" href={cms.landingPageCallToAction.button[0].linkUrl} light={true}>
            {cms.landingPageCallToAction.button[0].buttonText}
          </Button>
        </div>
        <div style={{ height: "100%" }}>
          <img style={{ height: "100%" }} src={cms.landingPageCallToAction.image && cms.landingPageCallToAction.image.url} alt="Donations" />
        </div>
      </div>
      {cms.landingPageShowDonors && donors?.recent?.length > 0 && (
        <>
          <h2 className="txtCenter eb">Recent Donors</h2>
          <DonorBoard donors={donors?.recent} />
          <Button buttonType="link" href="/getinvolved/donors" extraStyles={{ margin: "32px auto", marginBottom: "0" }}>
            View All Donors
          </Button>
        </>
      )}
    </Page>
  );
}
