// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amenities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
}

@media (max-width: 600px) {
  .amenities {
    grid-template-columns: 1fr;
  }  
}
.amenity {
  display: flex;
  align-items: center;
}
.amenity img {
  margin-right: 10px;
  width: 20px;
}

.amenity p {
  margin: 8px 0;
}

.altPricing {
  margin-top: 48px;
  padding: 32px 0;
  background-color: var(--light);
  box-shadow: var(--box-shadow);
  text-align: center;
}

.altPricing p {
  max-width: none !important;
}`, "",{"version":3,"sources":["webpack://./src/css/Accommodations.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,8BAA8B;EAC9B,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".amenities {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 32px;\n}\n\n@media (max-width: 600px) {\n  .amenities {\n    grid-template-columns: 1fr;\n  }  \n}\n.amenity {\n  display: flex;\n  align-items: center;\n}\n.amenity img {\n  margin-right: 10px;\n  width: 20px;\n}\n\n.amenity p {\n  margin: 8px 0;\n}\n\n.altPricing {\n  margin-top: 48px;\n  padding: 32px 0;\n  background-color: var(--light);\n  box-shadow: var(--box-shadow);\n  text-align: center;\n}\n\n.altPricing p {\n  max-width: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
