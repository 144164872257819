// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button,
input[type="submit"] {
  transform: scale(1);
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark);
  filter: var(--shadow);
  color: var(--light);
  padding: 16px 32px;
  font-weight: 600;
  margin: 8px 0;
  cursor: pointer;
  transition: transform 100ms;
}

.Button:hover {
  transform: scale(1.02);
}

.Button:disabled {
  background-color: var(--dark-muted);
  transform: none;
  cursor: default;
}

.Button.light {
  background-color: var(--light);
  border: 3px solid var(--dark);
  color: var(--dark);
  font-weight: 600;
}
.buttonIcon {
  width: 20px;
}
.buttonIcon.left {
  margin-right: 10px;
}
.buttonIcon.right {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Button.css"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EAEnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mCAAmC;EACnC,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".Button,\ninput[type=\"submit\"] {\n  transform: scale(1);\n  width: -moz-fit-content;\n  width: fit-content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--dark);\n  filter: var(--shadow);\n  color: var(--light);\n  padding: 16px 32px;\n  font-weight: 600;\n  margin: 8px 0;\n  cursor: pointer;\n  transition: transform 100ms;\n}\n\n.Button:hover {\n  transform: scale(1.02);\n}\n\n.Button:disabled {\n  background-color: var(--dark-muted);\n  transform: none;\n  cursor: default;\n}\n\n.Button.light {\n  background-color: var(--light);\n  border: 3px solid var(--dark);\n  color: var(--dark);\n  font-weight: 600;\n}\n.buttonIcon {\n  width: 20px;\n}\n.buttonIcon.left {\n  margin-right: 10px;\n}\n.buttonIcon.right {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
