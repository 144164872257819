import React from 'react';
import './css/TodayActivityCard.css';

export default function TodayActivityCard({ day, date, month, activities }) {
    // Filter out past activities
    const visibleActivities = activities.filter(activity => !activity.isPast);

    // Group activities by time
    const groupedActivities = visibleActivities.reduce((acc, activity) => {
        if (!acc[activity.time]) {
            acc[activity.time] = [];
        }
        acc[activity.time].push(activity);
        return acc;
    }, {});

    const currentLabel = "Happening Now";

    return (
        <div className='today-activity-card'>
            <div className='activity-card-header'>
                <div className='activity-card-date'>
                    <div className='day'>{day}</div>
                    <div className='date'>{date}</div>
                    <div className='month'>{month}</div>
                </div>
                {/* Use for possible birthday implementation
                {event && (
                    <div className='activity-card-event'>
                        <div className='event-icon'>
                            <img className='birthday-event' src='/birthday_event.svg' alt='Birthday Event' />
                        </div>
                        <div className='event'>{event}</div>
                    </div>
                )}
                */}
            </div>
            <div className='activity-section'>
                {Object.keys(groupedActivities).map((time, index) => {

                    // Check for activities that are currently happening
                    const isCurrentTime = groupedActivities[time].some(activity => activity.isCurrent);

                    return (
                        <div key={index} className={`activity-block ${isCurrentTime ? 'current-activity-block' : ''}`}>
                            <div className={`hide-label ${isCurrentTime ? 'show-label' : ''}`}>{currentLabel}</div>
                            <div className='details'>
                                <div className={`activity-time ${isCurrentTime ? 'current-activity-time' : ''}`}>{time}</div>
                                <div className='activities'>
                                    {groupedActivities[time].map((activity, activityIndex) => (
                                        <div
                                            className={`activity ${activity.isCurrent ? 'current-activity' : ''}`}
                                            key={activityIndex}
                                        >
                                            <div className='activity-name'>{activity.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
