import React from "react";

function MobileRow({ data, variables, headings }) {
  return (
    <tr>
      <td>
        {variables.map((el, index) => {
          // dig into nested programatic object keys. i.e. "el.departments.title"
          const vars = el.split(".");
          let tmp = data;
          vars.forEach((item) => {
            tmp = tmp[item];
          });
          // special case for staff lookup component
          if (headings[index] === "First") {
            return (
              <p key={el + tmp}>
                <strong>
                  {data.firstName} {data.lastName}
                </strong>
              </p>
            );
          }
          // special case for staff lookup component
          if (headings[index] === "Last") {
            return;
          }
          if (index === 0) {
            return (
              <p key={el + tmp}>
                <strong>{tmp}</strong>
              </p>
            );
          }

          return (
            <p key={el + tmp}>
              {headings[index]} - {tmp}
            </p>
          );
        })}
      </td>
    </tr>
  );
}

export default MobileRow;
