import React from "react";
import { useSelector } from "react-redux";
import Page from "../../components/page_components/Page";
import CardGroup from "../../components/cards/CardGroup";
import PageHeader from "../../components/page_components/PageHeader";

export default function ServicesLanding() {
  const { servicePages, servicesPage } = useSelector((state) => state.cms);
  let cms = servicesPage;
  if (!cms) return null;
  return (
    <Page sidebarType="services">
      <div className="container">
        <PageHeader text={servicesPage.pageTitle} />
      </div>

      {servicePages && servicePages.length > 0 && (
        <CardGroup
          cards={servicePages.map((el) => {
            if (!el.cardView) return null;
            const { title, body, image } = el.cardView;
            return {
              img: image && image.url,
              altText: image && image.alternativeText,
              header: title,
              content: body,
              buttonText: "More Info",
              href: el.slug,
            };
          })}
        />
      )}
    </Page>
  );
}
