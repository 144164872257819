import React, {} from 'react';
import './css/MenuCard.css';

// temporary code, might change when integrated with actual db
export default function MenuCard({ day, date, month, breakfastDescription, lunchDescription, dinnerDescription, isToday, isTomorrow }) {
    const displayDay = isToday ? "Today" : isTomorrow ? "Tomorrow" : day;

    return (
        <div className={`menu-card ${isToday ? 'today-card' : isTomorrow ? 'tomorrow-card' : ''}`}>
            <div className='menu-card-header'>
                <div className='day'>{displayDay}</div>
                <div className='date'>{date}</div>
                <div className='month'>{month}</div>
            </div>
            <div className='menu-section'>
                <div className='menu-title'><strong>Breakfast</strong></div>
                <div className='menu-description'>{breakfastDescription}</div>
            </div>
            <hr className='divider' />
            <div className='menu-section'>
                <div className='menu-title'><strong>Lunch</strong></div>
                <div className='menu-description'>{lunchDescription}</div>
            </div>
            <hr className='divider' />
            <div className='menu-section'>
                <div className='menu-title'><strong>Dinner</strong></div>
                <div className='menu-description'>{dinnerDescription}</div>
            </div>
        </div>
    );
}