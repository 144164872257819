// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LgServiceCardGrid {
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
  
  .LgServiceCard {
    background-color: var(--light);
    padding: 32px;
    box-shadow: var(--box-shadow);
  }
  
  .LgServiceCard img {
    width: 30px;
  }
  
  .LgServiceCard > div {
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  
  .LgServiceCard h3 {
    margin: 0;
  }
  
  .LgServiceCard p {
    line-height: 1.5em;
  }
  
  @media (max-width: 1150px) {
    .LgServiceCardGrid {
      grid-template-columns: 1fr;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/LgServiceCard.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,cAAc;EAChB;;EAEA;IACE,8BAA8B;IAC9B,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE;MACE,0BAA0B;IAC5B;EACF","sourcesContent":[".LgServiceCardGrid {\n    max-width: 1100px;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 16px;\n  }\n  \n  .LgServiceCard {\n    background-color: var(--light);\n    padding: 32px;\n    box-shadow: var(--box-shadow);\n  }\n  \n  .LgServiceCard img {\n    width: 30px;\n  }\n  \n  .LgServiceCard > div {\n    display: flex;\n    align-items: center;\n    margin-top: 8px;\n    margin-bottom: 32px;\n  }\n  \n  .LgServiceCard h3 {\n    margin: 0;\n  }\n  \n  .LgServiceCard p {\n    line-height: 1.5em;\n  }\n  \n  @media (max-width: 1150px) {\n    .LgServiceCardGrid {\n      grid-template-columns: 1fr;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
