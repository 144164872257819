import React from "react";
import LgServiceCard from "./LgServiceCard";
import "../css/LgServiceCard.css";
import { v4 as uuid } from "uuid";

export default function LgServiceCardGrid({ items }) {
  return (
    <div className="LgServiceCardGrid">
      {items.map((el) => {
        return <LgServiceCard key={uuid()} header={el.title} icon={el.image} content={el.body} />;
      })}
    </div>
  );
}
