import React from "react";
import "../../css/Card.css";
import Button from "../Button";

export default function Card(props) {
  const iconText = "fa-solid " + props.icon;

  return (
    <div className="Card">
      <img src={props.img} alt={props.altText} />
      <div className="text-area">
        <div>
          {props.icon ? <i className={iconText}></i> : undefined}
          <h3>{props.header}</h3>
        </div>
        <p className="eb">{props.content}</p>
        {(props.button1 || props.button2) && (
          <div className="row">
            {props.button1 && (
              <Button buttonType="link" href={props.button1.href} extraStyles={{ padding: "12px 24px" }}>
                {props.button1.text}
              </Button>
            )}
            {props.button2 && (
              <Button
                buttonType="link"
                href={props.button2.href}
                extraStyles={{
                  padding: "9px 21px",
                  backgroundColor: "transparent",
                  color: "var(--dark)",
                  border: "3px solid var(--dark)",
                }}
              >
                {props.button2.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Card.defaultProps = {
  img: "https://images.unsplash.com/photo-1531844734254-51193b49c604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80",
  altText: "",
  header: "Example",
  content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque aut adipisci placeat eligendi consequatur pariatur dolores",
  extraStyles: {},
};
