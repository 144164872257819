// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BoardMemberCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 3em;
    box-shadow: var(--box-shadow);
}

.BoardMemberCard .title { 
    margin-top: 0;
}

.BoardMemberCard h3 {
    margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/css/BoardMemberCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".BoardMemberCard {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 1em 3em;\n    box-shadow: var(--box-shadow);\n}\n\n.BoardMemberCard .title { \n    margin-top: 0;\n}\n\n.BoardMemberCard h3 {\n    margin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
