// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StaffIdCard {
    height: 150px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.StaffIdCard .details {
    background-color: var(--light);
    padding: 8px;
    max-width: 300px;
    border: 2px solid var(--dark);
    border-radius: 6px;
    filter: var(--shadow);
}

.StaffIdCard h2 {
    padding-top: 8px;
    margin: 8px 0;
}

.StaffIdCard p {
    margin: 0;
    font-size: 1.5vmin;
}

.StaffIdCard img {
    z-index: 2;
    width: 130px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    object-fit: cover;
    filter: var(--shadow);
}

@media (max-width: 600px) {
    .StaffIdCard .details {
        padding: 8px 8px 8px 64px;
        max-width: 240px;
    }
    .StaffIdCard img {
        width: 100px;
    }
}

@media (max-width: 830px) {
    .StaffIdCard p {
        font-size: 0.7em;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/StaffIdCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;IAC7B,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI;QACI,yBAAyB;QACzB,gBAAgB;IACpB;IACA;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".StaffIdCard {\n    height: 150px;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.StaffIdCard .details {\n    background-color: var(--light);\n    padding: 8px;\n    max-width: 300px;\n    border: 2px solid var(--dark);\n    border-radius: 6px;\n    filter: var(--shadow);\n}\n\n.StaffIdCard h2 {\n    padding-top: 8px;\n    margin: 8px 0;\n}\n\n.StaffIdCard p {\n    margin: 0;\n    font-size: 1.5vmin;\n}\n\n.StaffIdCard img {\n    z-index: 2;\n    width: 130px;\n    aspect-ratio: 1 / 1;\n    border-radius: 50%;\n    object-fit: cover;\n    filter: var(--shadow);\n}\n\n@media (max-width: 600px) {\n    .StaffIdCard .details {\n        padding: 8px 8px 8px 64px;\n        max-width: 240px;\n    }\n    .StaffIdCard img {\n        width: 100px;\n    }\n}\n\n@media (max-width: 830px) {\n    .StaffIdCard p {\n        font-size: 0.7em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
