// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historyPage {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.imageWidth {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.historyPage h1,
.historyPage h2,
.historyPage p {
  text-align: left;
}

.historyPage img {
  display: inline-block;
  text-align: center;
}

.historyPage button {
  text-align: center;
}

.historyCard {
  display: flex;
  flex-direction: column;
  width: 45%;
  min-width: 200px;
  padding: 16px;
  flex-grow: 1;
  filter: var(--shadow);
  background-color: var(--light);
}

.historyCard img {
  width: auto;
  height: 250px;
  object-fit: cover;
}
.flexColumn {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.bookTour {
  text-align: center;
  display: grid;
}
`, "",{"version":3,"sources":["webpack://./src/css/History.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;;AAEA;;;EAGE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".historyPage {\n  max-width: 800px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.imageWidth {\n  width: 100%;\n  height: 300px;\n  object-fit: cover;\n}\n\n.historyPage h1,\n.historyPage h2,\n.historyPage p {\n  text-align: left;\n}\n\n.historyPage img {\n  display: inline-block;\n  text-align: center;\n}\n\n.historyPage button {\n  text-align: center;\n}\n\n.historyCard {\n  display: flex;\n  flex-direction: column;\n  width: 45%;\n  min-width: 200px;\n  padding: 16px;\n  flex-grow: 1;\n  filter: var(--shadow);\n  background-color: var(--light);\n}\n\n.historyCard img {\n  width: auto;\n  height: 250px;\n  object-fit: cover;\n}\n.flexColumn {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n  justify-content: center;\n}\n\n.bookTour {\n  text-align: center;\n  display: grid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
