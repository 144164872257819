import axios from "axios";

export const FETCH_PAGE_CMS = "FETCH_PAGE_CMS";
export const FETCH_SERVICE_PAGES = "FETCH_SERVICE_PAGES";
export const SET_SERVICE_CMS = "SET_SERVICE_CMS";
export const FETCH_SECTIONS = "FETCH_SECTIONS";
export const FETCH_NAV_MAIN = "FETCH_NAV_MAIN";
export const FETCH_NAV_FOOTER = "FETCH_NAV_FOOTER";
export const SET_GERMAN = "SET_GERMAN";

export const fetchPageCMS = (strapiSlug, storeVariable) => {
  console.log("Dispatching: ", strapiSlug, storeVariable);
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/${strapiSlug}`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_PAGE_CMS,
        storeVariable: storeVariable,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};

export const fetchServicePages = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/pages`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_SERVICE_PAGES,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};
export const fetchSections = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/sections`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_SECTIONS,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};

export const setSerivceCms = (data) => {
  return async (dispatch) => {
    try {
      //   dispatch action
      dispatch({
        type: SET_SERVICE_CMS,
        data: data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};

export const setGermanMode = (isGermanMode) => {
  return async (dispatch) => {
    try {
      //   dispatch action
      dispatch({
        type: SET_GERMAN,
        isGermanMode: isGermanMode,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};

export const fetchNavMain = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/main-navbar`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_NAV_MAIN,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};

export const fetchNavFooter = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/footer-navbar`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_NAV_FOOTER,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};

export const fetchTVGraphic = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/tv-graphic`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_NAV_FOOTER,
        data: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};
