import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../css/StaffIdCard.css";

export default function StaffIdCard({ data }) {
  const { staff } = useSelector((state) => state.staff);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [headshot, setHeadshot] = useState();
  const [title, setTitle] = useState();
  const [department, setDepartment] = useState();

  useEffect(() => {
    // let personalInfo = data;
    let firstName, lastName, headshot, title, department;
    
    if (data.__component === "people.person") {
      firstName = data.firstName;
      lastName = data.lastName;
      headshot = data.headshot;
      title = data.title;
      department = "";
    } else if (data.__component === "people.staff-component") {
      if (staff) {
        const staffId = data.id;
        const match = staff.filter((el) => el.id === staffId)[0];
        firstName = match.personalInfo.firstName;
        lastName = match.personalInfo.lastName;
        headshot = match.personalInfo.headshot;
        title = match.personalInfo.title;
        department = match.department.title;
      }
    }

    setFirstName(firstName);
    setLastName(lastName);
    setHeadshot(headshot);
    setTitle(title);
    setDepartment(department);
  }, [staff, data]);

  if (!data) return null;
  return (
    <div className="StaffIdCard">
      {headshot && (
        <div style={{ zIndex: "5", display: "flex", alignItems: "flex-end", height: "100%" }}>
          <img src={headshot.url} alt={"headshot of " + firstName + " " + lastName} />
        </div>
      )}

      <div>
        <h2>
          {firstName} {lastName}
        </h2>
        <div className="details" style={headshot ? { marginLeft: "-64px", paddingLeft: "64px" } : { textAlign: "center", padding: "16px" }}>
          <p>
            {title}
            {department && " - " + department}
          </p>
        </div>
      </div>
    </div>
  );
}
