// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 4.5% 4%;
    background-color: var(--light);
    filter: var(--shadow)
}

.ContactCard .contactInfo {
    display: flex;
    padding-right: 8px;
}

.ContactCard .contactInfo h3 {
    font-size: 1em;
}

.ContactCard .contactInfoText {
    word-break: break-all;
    flex-basis: 50%;
    margin: 0;
    font-size: 0.9em;
}

@media (max-width: 545px) {
    .ContactCard .contactInfoText {
        font-size: 0.75em;
    }
}

@media (max-width: 350px) {
    .ContactCard .contactInfoText {
        font-size: 0.6em;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/ContactCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,8BAA8B;IAC9B;AACJ;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".ContactCard {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    padding: 4.5% 4%;\n    background-color: var(--light);\n    filter: var(--shadow)\n}\n\n.ContactCard .contactInfo {\n    display: flex;\n    padding-right: 8px;\n}\n\n.ContactCard .contactInfo h3 {\n    font-size: 1em;\n}\n\n.ContactCard .contactInfoText {\n    word-break: break-all;\n    flex-basis: 50%;\n    margin: 0;\n    font-size: 0.9em;\n}\n\n@media (max-width: 545px) {\n    .ContactCard .contactInfoText {\n        font-size: 0.75em;\n    }\n}\n\n@media (max-width: 350px) {\n    .ContactCard .contactInfoText {\n        font-size: 0.6em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
