import React from "react";

function Pagination({ page, onNext, onBack }) {
  return (
    <div className="pagination" style={{marginTop: '8px'}}>
      <div onClick={onBack}><i style={{fontSize: '16px'}} className="fa-solid fa-chevron-left"></i></div>
      <span>{page + 1}</span>
      <div onClick={onNext}><i style={{fontSize: '16px'}} className="fa-solid fa-chevron-right"></i></div>
    </div>
  );
}

export default Pagination;
