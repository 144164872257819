import React, { useState } from "react";
import Button from "../Button";
import "../../css/Form.css";
import axios from "axios";
import Banner from "../Banner";

export default function ContactForm({ name, namePlaceholder, email, emailPlaceholder, message, messagePlaceholder, buttonLeft, buttonRight, buttonText }) {
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMessage, setFormMessage] = useState("");
  const [showBanner, setShowBanner] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let formData = new FormData(form);

    // make request
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/contact-form`,
      data: {
        name: formName,
        email: formEmail,
        message: formMessage,
      },
    };

    // handle request
    const response = await axios(config);
    if (response.status !== 200) {
      let message = "Something went wrong!";
      throw new Error(message);
    }
    setShowBanner(true);
    setFormName("");
    setFormEmail("");
    setFormMessage("");
  };

  return (
    <form className="Form" onSubmit={onSubmit}>
      <Banner isVisible={showBanner} setIsVisible={setShowBanner} message="Thanks for your messsage!" />
      <div className="inputWrapper">
        <label htmlFor="name">{name}</label>
        <input value={formName} onChange={(e) => setFormName(e.target.value)} className="textInput" type="text" name="name" id="name" placeholder={namePlaceholder} />
      </div>
      <div className="inputWrapper">
        <label htmlFor="email">{email}</label>
        <input value={formEmail} onChange={(e) => setFormEmail(e.target.value)} className="textInput" type="email" name="email" id="email" placeholder={emailPlaceholder} />
      </div>
      <div className="inputWrapper">
        <label htmlFor="message">{message}</label>
        <textarea
          value={formMessage}
          onChange={(e) => setFormMessage(e.target.value)}
          className="textInput"
          name="message"
          id="message"
          cols="30"
          rows="10"
          placeholder={messagePlaceholder}
        ></textarea>
      </div>
      <Button>
        <img src={buttonLeft} style={{ width: 18, marginRight: 8 }} className="iconSvg" />
        {buttonText}
        <img src={buttonRight} style={{ width: 18, marginLeft: 8 }} className="iconSvg" />
      </Button>
    </form>
  );
}
