import React from "react";
import "../../css/Button.css";
import Page from "../../components/page_components/Page";
import PageHeader from "../../components/page_components/PageHeader";

export default function ApplicationProcess() {
  return (
    <Page sidebarType="accommodations">
      <PageHeader text="Application Process" />
      <div style={{marginTop: '48px', marginBottom: '72px'}}>
        <h2>Long-term Care</h2>
        <p style={{ fontSize: "1.2em", lineHeight: "150%" }}>
          For Long-term Care, Applications are processed through HCCSS.
        </p>
        <a className="Button rounded" href="https://healthcareathome.ca/home">
          HCCSS Website
        </a>
      </div>

      <div style={{marginTop: '32px', marginBottom: '16px'}}>
        <h2>Edelweiss</h2>
        <p style={{ fontSize: "1.2em", lineHeight: "150%" }}>
          To apply to Edelweiss, fill out the application and send it
          to office@heidehof.com
        </p>
        <a className="Button rounded" href="https://heidehof.s3.ca-central-1.amazonaws.com/Edelweiss_Rental_Apartments_Information_5df3104e61.pdf" target="_blank" rel="noopener noreferrer">
          Edelweiss Application Form
        </a>
        <h2>Lindenhaus</h2>
        <p style={{ fontSize: "1.2em", lineHeight: "150%" }}>
          To apply to Lindenhaus, fill out the application and send it
          to office@heidehof.com.  The application form contains floor plans and pricing.
        </p>
          <a className="Button rounded" href="https://heidehof.s3.ca-central-1.amazonaws.com/Lindenhaus_Application_Floor_Plans_d39e9eb158.pdf" target="_blank" rel="noopener noreferrer">
            Lindenhaus Application
          </a>
      </div>
    </Page>
  );
}
