import React from "react";
import { NavLink } from "react-router-dom";
import DropdownNavLink from "./DropdownNavLink";
import "../../css/DesktopNavbar.css";
import { v4 as uuid } from "uuid";

function DesktopNavbar({ links }) {
  const isActive = ({ isActive }) => (isActive ? "currentNavLink navLink" : "navLink");
  return (
    <nav className="navbar-desktop">
      <img className="nav-logo" src="/logo_desktop.svg" alt="Heidehof Logo" />
      <div className="nav-links">
        {links &&
          links.map((el) => {
            if (el.subLinks.length === 0) {
              return (
                <NavLink key={uuid()} to={el.slug} className={isActive}>
                  {el.text}
                </NavLink>
              );
            } else {
              return (
                <DropdownNavLink key={uuid()} to={el.slug} className={isActive} subpages={el.subLinks}>
                  {el.text}
                </DropdownNavLink>
              );
            }
          })}
      </div>
    </nav>
  );
}

export default DesktopNavbar;
