import React from "react";
import "../css/Loading.css";

const Loading = () => {
  return (
    <div className="LoadingScreen">
      <span className="loader"></span>
    </div>
  );
};

export default Loading;
