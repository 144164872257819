import React, { useEffect, useState } from "react";
import Page from "../../components/page_components/Page";
import Button from "../../components/Button";
import "../../css/Staff.css";
import StaffIdCard from "../../components/cards/StaffIdCard";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import PageHeader from "../../components/page_components/PageHeader";
import RichText from "../../components/RichText";

export default function Staff() {
  const { testimonials } = useSelector((state) => state.testimonial);
  const [quotes, setQuotes] = useState([]);

  const { staffPage } = useSelector((state) => state.cms);
  let cms = staffPage;

  useEffect(() => {
    if (testimonials) {
      let tmp = [];
      // select the quotes for this page based on the slugs
      testimonials.forEach((element) => {
        let slugs =
          element.pageSlug &&
          element.pageSlug.filter(
            (el) => el.text === window.location.pathname || el.text === "*"
          );
        if (slugs.length >= 1) tmp.push(element);
      });
      setQuotes(tmp);
    }
  }, [testimonials]);

  if (!cms) return null;

  return (
    <Page>
      <div className="StaffPage">
        <div className="staffHero">
          <PageHeader text={cms.pageTitle} />
          <RichText text={cms.body} />
        </div>

        <div className="staffBottom">
          <p>{cms.callToAction.mainText}</p>

          <Button
            buttonType="link"
            href={cms.callToAction.button[0].linkUrl}
            light={true}
            extraStyles={{ margin: "0 auto" }}
          >
            {cms.callToAction.button[0].buttonText}
          </Button>
        </div>
        {cms.showTestimonials && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "16px",
            }}
          >
            {quotes &&
              quotes.map((el) => <StaffQuoteCard data={el} key={uuid()} />)}
          </div>
        )}
      </div>
    </Page>
  );
}

const StaffQuoteCard = ({ data }) => {
  const type = data.personalInfo[0].__component;

  let display;
  switch (type) {
    case "people.person":
      display = data.personalInfo[0];
      break;
    case "people.staff-component":
      display = {
        ...data.personalInfo[0].staff_member,
        __component: "people.staff-component",
      };
      break;
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <StaffIdCard data={display} />
      <div className="review">
        <i className="fa-solid fa-quote-left"></i>
        <p>{data.quote} </p>
      </div>
    </div>
  );
};
