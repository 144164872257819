import React, { useEffect, useState } from "react";
import RichText from "./RichText";

let textWithImageInstanceCount = 0;

export default function TextWithImage({ text, image }) {
  const [imgLeft, setImgLeft] = useState(true);

  // track how many instances are on page and display left or right accordingly
  useEffect(() => {
    textWithImageInstanceCount += 1;
    setImgLeft(textWithImageInstanceCount % 2 ? true : false);
    return () => {
      textWithImageInstanceCount -= 1;
    };
  }, []);

  return (
    <div className="twoColResponsiveGrid">
      <RichText text={text} />
      <img src={image.url} alt={image.alternativeText} style={imgLeft ? { gridColumn: 1, gridRow: 1 } : { gridColumn: 2, gridRow: 1 }} />
    </div>
  );
}
