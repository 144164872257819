import React from "react";
import "../../css/Footer.css";
import Button from "../Button";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const { footerNav } = useSelector((state) => state.cms);
  const navigate = useNavigate();
  return (
    <footer>
      <div className="footer-top">
        {footerNav &&
          footerNav.links &&
          footerNav.links.map((el) => {
            return (
              <div key={uuid()} className="footer-column">
                <Link className="eb" to={el.slug}>
                  {el.text}
                </Link>
                {el.subLinks.map((link) => {
                  return (
                    <Link key={uuid()} to={link.slug}>
                      {link.text}
                    </Link>
                  );
                })}
              </div>
            );
          })}
      </div>
      <div className="footer-bottom">
        <div style={{ display: "flex" }}>
          {footerNav.socials &&
            footerNav.socials.map((el) => {
              return (
                <a key={uuid()} href={el.link} target="_blank" rel="noopener noreferrer" className="icon-link">
                  <img src={el.icon && el.icon.url} style={{ height: 40 }} className="iconSvg" />
                </a>
              );
            })}
          {footerNav.callToActionButton && (
            <Button
              light={true}
              buttonType="icon"
              iconLeft={footerNav.callToActionButton.iconLeft && footerNav.callToActionButton.iconLeft.url}
              iconRight={footerNav.callToActionButton.iconRight && footerNav.callToActionButton.iconRight.url}
              onClick={() => navigate(footerNav.callToActionButton.linkUrl)}
            >
              {footerNav.callToActionButton.buttonText}
            </Button>
          )}
        </div>
        <Link to="/" className="cinzel">
          <img src={footerNav.logo && footerNav.logo.url} style={{ height: 50 }} />
        </Link>
        <p data-date={new Date().getFullYear()}>
          <a href="https://www.civiconnect.ca/" target="_blank" rel="noopener noreferrer">
            Powered by Civiconnect
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
