import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import Donor from "./Donor";
import "../../css/DonorBoard.css";

export default function DonorBoard({ donors=[], category="recent" }) {

  return (
    <>
      <div className="DonorBoard">
        {donors && donors.map((el) => {
          return <Donor name={el.donorName} date={el.donationDate} category={category} key={uuid()} />;
        })}
      </div>
    </>
  );
}
