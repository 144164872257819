import React from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import BookTourCTACard from "../../components/cards/BookTourCTACard";
import Page from "../../components/page_components/Page";
import PageHeader from "../../components/page_components/PageHeader";
import RichText from "../../components/RichText";
import "../../css/History.css";
import { v4 as uuid } from "uuid";

export default function History() {
  const { historyPage } = useSelector((state) => state.cms);
  let cms = historyPage;
  if (!cms) return null;
  const { pageTitle, section1, cards, section2, callToAction } = cms;

  // mainText;
  // subText;
  // button;
  // image;

  // buttonText;
  // linkUrl;

  // text;
  // image;
  return (
    <Page>
      <div className="historyPage">
        <PageHeader text={pageTitle} />

        <RichText text={section1} />

        {/* Creating flexbox for 2 images */}

        <div className="flexColumn">
          {cards &&
            cards.map((el) => {
              return (
                <div className="historyCard rounded" key={uuid()}>
                  <img src={el.image && el.image.url} alt="insert alt text here" />
                  <p>{el.text}</p>
                </div>
              );
            })}
        </div>
        <RichText text={section2} />
      </div>
      <BookTourCTACard mainText={callToAction.mainText} subText={callToAction.subText} linkUrl={callToAction.button[0].linkUrl} buttonText={callToAction.button[0].buttonText} />
    </Page>
  );
}
