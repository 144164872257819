import { FETCH_DEPARTMENTS, FETCH_STAFF_MEMBERS } from "../actions/staff";

const initialState = {
  staff: null,
  departments: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STAFF_MEMBERS:
      return {
        ...state,
        staff: action.staff,
      };
    case FETCH_DEPARTMENTS:
      return {
        ...state,
        departments: action.departments,
      };
    default:
      return state;
  }
};
