import { useEffect, useState } from "react";
import "./css/App.css";
import Navigation from "./navigation/Navigation";
import { useDispatch, useSelector } from "react-redux";
import * as AccommodationActions from "./store/actions/accommodations";
import * as TestimonialActions from "./store/actions/testimonials";
import * as StaffActions from "./store/actions/staff";
import * as ArticleActions from "./store/actions/articles";
import * as DonorActions from "./store/actions/donors";
import * as EventActions from "./store/actions/events";
import * as BoardMembersActions from "./store/actions/board-members";
import * as CmsActions from "./store/actions/cms";
import Loading from "./pages/Loading";

function App() {
  const dispatch = useDispatch();
  const { landingPage, servicesPage, accommodationsPage, contactPage, getInvolvedPage, historyPage, newsPage, staffApplicationPage, staffPage, subPages } = useSelector((state) => state.cms);
  const [loading, setLoading] = useState(false);

  // load our data
  useEffect(() => {
    setLoading(true);

    dispatch(CmsActions.fetchNavMain());
    dispatch(CmsActions.fetchNavFooter());
    dispatch(CmsActions.fetchServicePages());
    dispatch(CmsActions.fetchSections());

    if (!landingPage) dispatch(CmsActions.fetchPageCMS("landing-page", "landingPage"));
    if (!accommodationsPage) dispatch(CmsActions.fetchPageCMS("accommodations-page", "accommodationsPage"));
    if (!contactPage) dispatch(CmsActions.fetchPageCMS("contact-page", "contactPage"));
    if (!getInvolvedPage) dispatch(CmsActions.fetchPageCMS("get-involved-page", "getInvolvedPage"));
    if (!historyPage) dispatch(CmsActions.fetchPageCMS("history-page", "historyPage"));
    if (!newsPage) dispatch(CmsActions.fetchPageCMS("news-page", "newsPage"));
    if (!staffApplicationPage) dispatch(CmsActions.fetchPageCMS("staff-application-page", "staffApplicationPage"));
    if (!staffPage) dispatch(CmsActions.fetchPageCMS("staff-page", "staffPage"));
    if (!servicesPage) dispatch(CmsActions.fetchPageCMS("services-page", "servicesPage"));
    if (!subPages) dispatch(CmsActions.fetchPageCMS("sub-pages", "subPages"));

    dispatch(TestimonialActions.fetchTestimonials());
    dispatch(AccommodationActions.fetchAccommodations());
    dispatch(DonorActions.fetchDonors());
    dispatch(ArticleActions.fetchArticles());
    dispatch(BoardMembersActions.fetchBoardMembers());
    dispatch(EventActions.fetchEvents());
    dispatch(StaffActions.fetchStaffMembers());
    dispatch(StaffActions.fetchDepartments());
  }, []);

  // once data is loaded, display our site
  useEffect(() => {
    if (landingPage && servicesPage && accommodationsPage && contactPage && getInvolvedPage && historyPage && newsPage && staffApplicationPage && staffPage) {
      setLoading(false);
    }
  }, [landingPage, servicesPage, accommodationsPage, contactPage, getInvolvedPage, historyPage, newsPage, staffApplicationPage, staffPage]);

  if (loading) return <Loading />;
  return <Navigation />;
}

export default App;
