// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridColumns {
  /* grid settings */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  /* text settings */
  line-height: 150%;
  text-align: left;
  line-height: 150%;
  max-width: 1000px;
}

.gridColumns > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.volunteerWorkGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 32px auto;
    max-width: 900px;
    text-align: center;
}

.volunteerWorkGrid div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px;
}

.volunteerWorkGrid p {
    margin-top: 12px;
    margin-bottom: 0px;
}

.volunteerWorkGrid i {
    font-size: 1.6rem;
    color: var(--dark);
    filter: var(--shadow-muted);
}

@media (max-width: 750px) {
  .gridColumns {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 950px) and (max-width:1150px) {
    .gridColumns {
      grid-template-columns: 1fr;
    }
  }

@media (max-width: 500px) {
    .volunteerWorkGrid {
      grid-template-columns: 1fr 1fr;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/css/Volunteer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;AAEA;IACI;MACE,0BAA0B;IAC5B;EACF;;AAEF;IACI;MACE,8BAA8B;IAChC;EACF","sourcesContent":[".gridColumns {\n  /* grid settings */\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-gap: 16px;\n  /* text settings */\n  line-height: 150%;\n  text-align: left;\n  line-height: 150%;\n  max-width: 1000px;\n}\n\n.gridColumns > div img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.volunteerWorkGrid {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    margin: 32px auto;\n    max-width: 900px;\n    text-align: center;\n}\n\n.volunteerWorkGrid div {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 24px 16px;\n}\n\n.volunteerWorkGrid p {\n    margin-top: 12px;\n    margin-bottom: 0px;\n}\n\n.volunteerWorkGrid i {\n    font-size: 1.6rem;\n    color: var(--dark);\n    filter: var(--shadow-muted);\n}\n\n@media (max-width: 750px) {\n  .gridColumns {\n    grid-template-columns: 1fr;\n  }\n}\n\n@media (min-width: 950px) and (max-width:1150px) {\n    .gridColumns {\n      grid-template-columns: 1fr;\n    }\n  }\n\n@media (max-width: 500px) {\n    .volunteerWorkGrid {\n      grid-template-columns: 1fr 1fr;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
