import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../css/StaffContactBanner.css";
import StaffIdCard from "./cards/StaffIdCard";
import { v4 as uuid } from "uuid";

export default function StaffContactBanner({ header }) {
  const { staff } = useSelector((state) => state.staff);
  const [contactBanners, setContactBanners] = useState();

  useEffect(() => {
    if (staff) {
      let tmp = [];
      // select the data for this page based on the slugs
      staff.forEach((element) => {
        let slugs = element.contactCtaSlug && element.contactCtaSlug.filter((el) => el.text === window.location.pathname || el.text === '*');
        if (slugs.length >= 1) tmp.push(element);
      });
      setContactBanners(tmp);
    }
  }, [staff]);

  if (!contactBanners) return null;

  const renderBanners = () => {
    return contactBanners.map((el) => {
      el = { ...el, __component: "people.staff-component" };
      return (
        <div key={uuid()} className="StaffContactBanner fullPageWidth">
          <h2 className="eb" style={{ maxWidth: "420px", textAlign: "center" }}>
            {header}
          </h2>
          <StaffIdCard data={el} />
          <div className="contactInfo">
            <div>
              <h3>
                <i className="fa-solid fa-phone" style={{ marginRight: "8px", fontSize: "0.8em" }}></i>Phone Number
              </h3>
              <p>{el.phone}</p>
            </div>
            <div>
              <h3>
                <i className="fa-solid fa-envelope" style={{ marginRight: "8px", fontSize: "0.8em" }}></i>Email
              </h3>
              <p>{el.email}</p>
            </div>
          </div>
          {el.hours && <p>{el.hours}</p>}
        </div>
      );
    });
  };

  return <>{renderBanners()}</>;
}
