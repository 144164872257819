import React, { useEffect, useState } from "react";
import "../css/ImageLightbox.css";

function ImageLightbox({ photos }) {
  const [index, setIndex] = useState(0);
  const [changed, setChanged] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);

  const handleFullscreen = () => {
    if (fullscreen) {
      setFullscreen(false);
      document.removeEventListener("keydown", () => setFullscreen(false), false);
    } else {
      setFullscreen(true);
      document.addEventListener("keydown", () => setFullscreen(false), false);
    }
  };

  //   change image and animate the change
  const changeIndex = (i) => {
    let tmp = i;
    if (tmp < 0) tmp = 0;
    if (tmp >= photos.length) tmp = photos.length - 1;

    if (tmp !== index) {
      setChanged(true);
      setTimeout(() => {
        setChanged(false);
        setIndex(tmp);
      }, 200);
    }
  };

  if (!photos) return null;
  return (
    <div className={`lightbox ${fullscreen && "fullscreen"}`} onClick={() => handleFullscreen()}>
      <div className="fullscreenBtn">
        {!fullscreen ? <i className="fa-solid fa-expand"></i> : <i className="fa-solid fa-x"></i>}
      </div>
      <div onClick={(e) => { e.stopPropagation(); changeIndex(index - 1)}} className="lightBoxBtn left">
        <i className="fa-solid fa-arrow-left"></i>
      </div>
      <div onClick={(e) => { e.stopPropagation(); changeIndex(index + 1) }} className="lightBoxBtn right">
        <i className="fa-solid fa-arrow-right"></i>
      </div>
      <div className={`lightboxTop ${changed ? "fadeOut" : "fadeIn"}`} onClick={(e) => { fullscreen && e.stopPropagation(); }}>
        <img key={photos[index].url} src={photos[index].url} className="displayedImage" />
      </div>
      <div className="lightboxBottom" onClick={(e) => e.stopPropagation()}>
        {photos.map((el, i) => (
          <div key={el.url} className={`thumbnailContainer ${i === index && "currentThumbnail"}`}>
            <img src={el.formats.thumbnail.url} className="thumbnail" onClick={() => changeIndex(i)} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageLightbox;
