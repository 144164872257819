import React from "react";
import Page from "../../components/page_components/Page";
import PageHeader from "../../components/page_components/PageHeader";
import BoardMemberCard from "./BoardMemberCard";
import { v4 as uuid } from "uuid"
import { useSelector } from "react-redux";

export default function BoardMembers() {
    
  const { boardMembers } = useSelector((state) => state.boardMember);

  return (
    <Page sidebarType="getInvolved">
      <PageHeader text="Board of Directors" />
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {boardMembers &&
          boardMembers
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((member) => {
              return (
                <BoardMemberCard
                  key={uuid()} 
                  name={member.Name}
                  title={member.Title}
                  activeSince={member.ActiveSince}
                />
              );
            })}
      </div>
    </Page>
  );
}
