import React from "react";

function Row({ data, variables }) {
  return (
    <tr>
      {variables.map((el) => {
        // dig into nested programatic object keys. i.e. "el.departments.title"
        const vars = el.split(".");
        let tmp = data;
        vars.forEach((item) => {
          tmp = tmp[item];
        });
        return <td key={el + tmp}>{tmp}</td>;
      })}
    </tr>
  );
}

export default Row;
