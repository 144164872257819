import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../css/MobileNavbar.css";
import { v4 as uuid } from "uuid";
import AccordionNavLink from "./AccordionNavLink";

export default function MobileNavbar({ links }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openAccordion, setOpenAccordion] = useState("none");

  const toggleIsOpen = () => {
    setIsOpen((current) => !current);
  };

  const isActive = ({ isActive }) => (isActive ? "currentMobileNavLink mobileNavLink" : "mobileNavLink");

  const changeAccordion = (name) => {
    if (openAccordion === name) {
      setOpenAccordion("none");
    } else {
      setOpenAccordion(name);
    }
  };

  return (
    <nav>
      <div className="mobile-navbar-header">
        <img className="nav-logo" src="/logo_mobile.svg" alt="Heidehof Logo" />
        <button aria-label="Open dropdown navigation" onClick={toggleIsOpen} className="menu-container" aria-expanded={isOpen}>
          <i className={isOpen ? "fa-solid fa-chevron-up" : "fa-solid fa-bars"}></i>
        </button>
      </div>
      <div className={isOpen ? "mobile-navbar-dropdown down" : "mobile-navbar-dropdown up"} aria-label="Dropdown navigation menu">
        <ul>
          {links &&
            links.map((el, i) => {
              if (el.subLinks.length === 0) {
                return (
                  <li key={uuid()}>
                    <NavLink tabIndex={isOpen ? 0 : -1} to={el.slug} className={isActive}>
                      {el.text}
                    </NavLink>
                  </li>
                );
              } else {
                return (
                  <li key={uuid()} onClick={() => changeAccordion(i)}>
                    <AccordionNavLink tabIndex={isOpen ? 0 : -1} isOpen={openAccordion === i} to={el.slug} className={isActive} sublinks={el.subLinks}>
                      {el.text}
                    </AccordionNavLink>
                  </li>
                );
              }
            })}
        </ul>
      </div>
    </nav>
  );
}
