import React, { useEffect, useState } from "react";
import Page from "../../components/page_components/Page";
import Card from "../../components/cards/Card";
import "../../css/CardGroup.css";
import QuoteCard from "../../components/cards/QuoteCard";
import PageHeader from "../../components/page_components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import "../../css/Accommodations.css";
import { v4 as uuid } from "uuid";

export default function AccommodationsLanding() {
  const { accommodations } = useSelector((state) => state.accommodation);
  const { accommodationsPage } = useSelector((state) => state.cms);
  let cms = accommodationsPage;
  if (!cms) return null;

  return (
    <Page sidebarType="accommodations">
      <div className="container">
        <PageHeader text={cms.landingPageTitle} />

        <div className="CardGroup">
          {accommodations &&
            accommodations.map((el) => (
              <Card
                key={uuid()}
                header={el.title}
                img={el.photos && el.photos.length > 0 && el.photos[0].url}
                content={el.cardSummary}
                button1={{
                  href: `/accommodations/${el.slug}`,
                  text: "More Info",
                }}
              />
            ))}
        </div>
        <QuoteCard />
      </div>
    </Page>
  );
}
