import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./css/TVGraphic.css";
import DonorBoard from "./components/donor_board/DonorBoard";
import { formatDate, getDonorsAtIndex } from "./util/util";
import Clock from "./Clock";

export default function TVGraphic() {
  const slideshowInterval = 180000; //in ms, how often the background image changes
  const donorInterval = 30000; //in ms, how often the donor board goes to next donor

  const donorPaginationLimit = 30;

  const [slideshowImgs, setSlideshowImgs] = useState([]);
  const [currentPhoto, setCurrentPhoto] = useState(1);

  const [currentDonorIdx, setCurrentDonorIdx] = useState(0);
  const [currentDonorType, setCurrentDonorType] = useState("benefactor");

  const [currentWeather, setCurrentWeather] = useState({
    temp: 0,
    imgId: "04d",
    description: "loading",
  });

  const { donors } = useSelector((state) => state.donor);

  // fetch data from tvgraphic single type
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/tv-graphic`)
      .then((response) => response.json())
      .then((data) => {
        // set the stuff
        setSlideshowImgs(data.Slideshow);
        data.Slideshow.map((img) => {});
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // change photo every n seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPhoto((idx) => {
        if (idx === slideshowImgs.length - 1) {
          return 0;
        }
        return idx + 1;
      });
    }, slideshowInterval);
    return () => clearTimeout(timer);
  }, [slideshowImgs]);

  // change donor every n seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDonorIdx((idx) => {
        if (donors && idx + calculateIncrement(currentDonorType) > combineDonors(donors).length - donorPaginationLimit - donorPaginationLimit - 3) {
          setCurrentDonorType("benefactor");
          return 0;
        }
        const nextDonorIdx = idx + calculateIncrement(currentDonorType);
        setCurrentDonorType(getDonorsAtIndex(nextDonorIdx, donorPaginationLimit, donors).donorType);

        return nextDonorIdx;
      });

      console.log(currentDonorIdx);
    }, donorInterval);
    return () => clearInterval(timer);
  }, [donors, currentDonorIdx, currentDonorType]);

  // set weather
  useEffect(() => {
    getWeatherData();
    const timer = setInterval(() => {
      getWeatherData();
    }, 60000); //every 60 seconds, check the weather.  Don't set this too low or you'll block access to API (max 60reqs/s)
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className="TVGraphic"
      style={{
        backgroundImage: slideshowImgs.length > 0 && `url(${slideshowImgs[currentPhoto].url})`,
        backgroundSize: "cover",
        transition: "background-image 0.4s ease-in-out",
      }}
    >
      <div className="weather">
        <p
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            marginTop: "8px",
            paddingBottom: "0px",
          }}
        >
          {currentWeather.temp}°C
        </p>
        <img src={`http://openweathermap.org/img/wn/${currentWeather.imgId}@2x.png`} alt="cloudy" className="weatherIcon" />
        <p style={{ fontSize: "1em", marginBottom: "12px", textAlign: "center" }}>{currentWeather.description}</p>
      </div>
      <div className="sidebar">
        <div>
          <h2 className="eb txtCenter" style={{ margin: "0 auto" }}>
            {currentDonorType?.charAt(0).toUpperCase() + currentDonorType?.slice(1) + "s" /* capitalize, add s */}
          </h2>
          <p className="txtCenter donorDescription" style={{ margin: "8px auto 16px auto" }}>
            {getDescriptionText(currentDonorType)}
          </p>

          <DonorBoard donors={donors && getDonorsAtIndex(currentDonorIdx, donorPaginationLimit, donors).donors} category={currentDonorType} />
        </div>
        <p className="txtCenter">
          <strong>Thank you</strong> to our gracious donors
        </p>
      </div>
      <div className="bottomBanner">
        <div style={{ width: "400px" }}>
          <img className="nav-logo" src="/logo_dark.svg" alt="Heidehof Logo" />
        </div>

        <div style={{ display: "flex" }}>
          <Clock />
        </div>
        <div
          className="qrPrompt"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div className="cta" style={{ display: "flex", flexDirection: "column" }}>
            <p className="ctaHeader">Want to make a donation?</p>
            <p>
              You can donate online at <b>heidehof.com/donate</b>
              <br></br>Or scan the QR code on the right
            </p>
          </div>
          <img style={{ width: "60px" }} src="/qrcode.svg" alt="QR Code" />
        </div>
      </div>
      <div
        id="preload-images"
        style={{
          background: slideshowImgs.map((img) => `url(${img.url}) no-repeat -9999px -9999px`).join(", "),
        }}
      ></div>
    </div>
  );

  function calculateIncrement(donorType) {
    switch (donorType) {
      case "benefactor":
        return 13;
      case "patron":
        return 13;
      case "friend":
        return 13;
      default:
        return 1;
    }
  }

  function getDescriptionText(donorType) {
    switch (donorType) {
      case "benefactor":
        return "Donors who have contributed $25,000 & over";
      case "patron":
        return "Donors who have contributed $10,000 - $24,999";
      case "friend":
        return "Donors who have contributed from $1,000 - $9,999";
      default:
        return 1;
    }
  }

  function getWeatherData() {
    fetch("https://api.openweathermap.org/data/2.5/weather?lat=43.19&lon=-79.25&units=metric&appid=aaf598c75f8fb034c099610f3e6aaf56")
      .then((response) => response.json())
      .then((data) => {
        // set the stuff
        console.log(data);
        setCurrentWeather({
          temp: Math.round(data.main.temp),
          imgId: data.weather[0].icon,
          description: data.weather[0].main,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function combineDonors(donors) {
    let tmp = [];
    donors.recent.map((el) => tmp.push(el));
    donors.benefactor.map((el) => tmp.push(el));
    donors.patron.map((el) => tmp.push(el));
    donors.friend.map((el) => tmp.push(el));
    return tmp;
  }
}
