import React, { Fragment, useEffect, useState } from "react";
import "../../css/Sidebar.css";
import IconLink from "../IconLink";
import Button from "../Button";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";

export default function Sidebar(props) {
  const { mainNav, contactPage, accommodationsPage, getInvolvedPage } = useSelector((state) => state.cms);
  const [data, setData] = useState({});

  useEffect(() => {
    const currentSlug = window.location.pathname.split("/")[1];
    if (mainNav && mainNav.links) {
      let tmp = mainNav.links.filter((el) => el.slug.split("/")[1] === currentSlug)[0];
      setData(tmp);
    }
  }, [mainNav, window.location.pathname]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((current) => !current);
  };

  const extraContent = (type) => {
    switch (type) {
      case "book":
        if (!accommodationsPage.sideBarButton) return null;
        return (
          <Button buttonType="link" href="/contact/book-tour" width="100%" tabIndex={isOpen ? 0 : -1}>
            {accommodationsPage.sideBarButton.iconLeft && <img src={accommodationsPage.sideBarButton.iconLeft.url} style={{ width: 15, marginRight: 10 }} className="iconSvg" />}
            {accommodationsPage.sideBarButton.buttonText}
            {accommodationsPage.sideBarButton.iconRight && <img src={accommodationsPage.sideBarButton.iconRight.url} style={{ width: 15, marginLeft: 10 }} className="iconSvg" />}
          </Button>
        );

      case "donate":
        if (!getInvolvedPage.sideBarButton) return null;
        return (
          <Button buttonType="link" href="/getinvolved/donate" width="100%" tabIndex={isOpen ? 0 : -1}>
            {getInvolvedPage.sideBarButton.iconLeft && <img src={getInvolvedPage.sideBarButton.iconLeft.url} style={{ width: 15, marginRight: 10 }} className="iconSvg" />}
            {getInvolvedPage.sideBarButton.buttonText}
            {getInvolvedPage.sideBarButton.iconRight && <img src={getInvolvedPage.sideBarButton.iconRight.url} style={{ width: 15, marginLeft: 10 }} className="iconSvg" />}
          </Button>
        );

      case "hours":
        return (
          <div className="contactInfoPanel">
            {contactPage &&
              contactPage.sideBarItems &&
              contactPage.sideBarItems.map((el) => {
                return (
                  <Fragment key={uuid()}>
                    <h3>
                      <img src={el.icon && el.icon.url} style={{ width: 15, marginRight: 10 }} className="iconSvg" />
                      {el.mainText}
                    </h3>
                    <p>{el.subText}</p>
                  </Fragment>
                );
              })}
          </div>
        );

      default:
        return null;
        break;
    }
  };

  let buttonContents = (open) => {
    return (
      <>
        {open ? "Collapse" : data.text}
        <i className={open ? "fa-solid fa-chevron-left" : "fa-solid fa-chevron-right"} style={{ marginLeft: "20px" }}></i>
        <i className={open ? "fa-solid fa-chevron-left" : "fa-solid fa-chevron-right"}></i>
      </>
    );
  };

  if (props.sidebarType !== "none") {
    return (
      <nav aria-label="Sidebar">
        <Button className={isOpen ? "toggleSidebarBtn open" : "toggleSidebarBtn closed"} onClick={toggleIsOpen} ariaLabel="Sidebar Toggle Button" ariaExpanded={isOpen}>
          {buttonContents(isOpen)}
        </Button>
        <div className={isOpen ? "Sidebar open" : "Sidebar closed"}>
          <div>
            <div className="mobileSidebarSpacer"></div>
            <h2 style={{ fontWeight: "600", fontSize: "1.5rem", margin: "0.75rem 0" }}>{data.text}</h2>
            <hr />
            {data &&
              data.subLinks &&
              data.subLinks.map((link) => {
                if (link.name !== "header") {
                  return <IconLink tabIndex={isOpen ? 0 : -1} key={uuid()} linkName={link.text} to={link.slug} icon={link.icon && link.icon.url} />;
                }
              })}
            <hr />
          </div>
          {extraContent(data.sidebarCta)}
        </div>
      </nav>
    );
  }
}
