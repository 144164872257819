import React from "react";
import Card from "./Card";
import MobileLinkGroup from "../MobileLinkGroup";
import "../../css/CardGroup.css";
import {v4 as uuid} from 'uuid';

export default function CardGroup({cards, condenseOnMobile=false}) {
  const findIcon = (pathName) => {
    switch (pathName) {
      case "/getinvolved/donors":
        return "fa-users";
      case "/getinvolved/volunteer":
        return "fa-handshake-angle";
      case "/getinvolved/donate":
        return "fa-circle-dollar-to-slot";
      case "/getinvolved/golf":
        return "fa-golf-ball-tee";
    }
  };

  return (
    <>
      <div
        className={condenseOnMobile ? "CardGroup condense" : "CardGroup"}
      >
        {cards.map((card) => {
          return <Card
            key={uuid()}
            img={card.img}
            altText={card.altText}
            header={card.header}
            content={card.content}
            button1={card.buttonText && { href: card.href, text: card.buttonText }}
          />;
        })}
      </div>
      {condenseOnMobile && <MobileLinkGroup
        links={
          cards.map((card) => {
            return {
              name: card.header,
              path: card.href,
              icon: findIcon(card.href)
            }
          })}
      />}
    </>
  );
}
