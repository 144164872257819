import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardGroup from "../../components/cards/CardGroup";
import Gallery from "../../components/Gallery";
import Page from "../../components/page_components/Page";
import StaffContactBanner from "../../components/StaffContactBanner";
import { v4 as uuid } from "uuid";
import Card from "../../components/cards/Card";
import "../../css/CardGroup.css";
import PageHeader from "../../components/page_components/PageHeader";

export default function BookTour() {
  const { accommodations } = useSelector((state) => state.accommodation);
  const { contactPage } = useSelector((state) => state.cms);
  let cms = contactPage;
  if (!cms) return null;

  return (
    <Page sidebarType="contact">
      <div style={{ margin: "0 auto", maxWidth: "1100px" }}>
        <PageHeader text={cms.tourPageTitle} />
      </div>

      {cms.tourPageShowContact && <StaffContactBanner />}

      {cms.tourPageShowAccommodations && (
        <div style={{ margin: "auto", width: "fit-content" }}>
          <p style={{ fontSize: "1.2em" }}>
            <strong>{cms.tourPageAccommodationsHeader}</strong>
          </p>
          <div className="CardGroup">
            {accommodations &&
              accommodations.map((el) => (
                <Card
                  key={uuid()}
                  header={el.title}
                  img={el.photos && el.photos.length > 0 && el.photos[0].url}
                  content={el.cardSummary}
                  button1={{ href: `/accommodations/${el.slug}`, text: "More Info" }}
                />
              ))}
          </div>
        </div>
      )}

      {/* images go into gallery as array of urls - images={['url1.com', 'url2.com', ...]} */}
      <div style={{ margin: "auto", maxWidth: "1050px" }}>
        <Gallery />
      </div>
    </Page>
  );
}
