import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CardGroup from "../../components/cards/CardGroup";
import QuoteCard from "../../components/cards/QuoteCard";
import Page from "../../components/page_components/Page";
import StaffContactBanner from "../../components/StaffContactBanner";
import "../../css/Volunteer.css";
import { v4 as uuid } from "uuid";
import PageHeader from "../../components/page_components/PageHeader";
import RichText from "../../components/RichText";

export default function Volunteer() {
  const { getInvolvedPage } = useSelector((state) => state.cms);
  let cms = getInvolvedPage;
  if (!cms) return null;

  return (
    <Page sidebarType="getInvolved">
      <div className="container">
        <PageHeader text={cms.volunteerPageTitle} />
      </div>
      {cms.volunteerPageTextImageSection && (
        <div className="gridColumns" style={{ margin: "auto" }}>
          <RichText text={cms.volunteerPageTextImageSection.text} />

          <div>
            <img src={cms.volunteerPageTextImageSection.image && cms.volunteerPageTextImageSection.image.url} alt="Volunteer hero" />
          </div>
        </div>
      )}

      {cms.volunteerPageShowContact && <StaffContactBanner />}

      <RichText style={{ textAlign: "center", margin: 'auto', width: 'fit-content' }} text={cms.volunteerPageAccomplishmentsText} />

      <div className="volunteerWorkGrid">
        {cms.volunteerPageAccomplishments &&
          cms.volunteerPageAccomplishments.map((activity) => (
            <div key={activity.mainText}>
              <img src={activity.icon && activity.icon.url} style={{ width: 30 }} className="iconSvg" />
              <p>
                <strong>{activity.mainText}</strong>
              </p>
              <p>{activity.subText}</p>
            </div>
          ))}
      </div>

      {cms.volunteerPageShowQuote && <QuoteCard />}

      <div style={{ margin: "auto", width: "fit-content" }}>
        <h2 className="eb">{cms.volunteerPageCardsHeader}</h2>

        <CardGroup
          condenseOnMobile={true}
          cards={cms.getInvolvedCards.map((el) => {
            return {
              header: el.mainText,
              content: el.subText,
              img: el.image && el.image.url,
              buttonText: el.button && el.button[0] && el.button[0].buttonText,
              href: el.button && el.button[0] && el.button[0].linkUrl,
            };
          })}
        />
      </div>
    </Page>
  );
}
