import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../css/DesktopNavbar.css";

function DropdownNavLink(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dropdown" onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <NavLink to={props.to} className={props.className}>
        {props.children}
        <i className="fa-solid fa-caret-down"></i>
      </NavLink>
      <div className={isOpen ? "dropdown-options rounded" : "dropdown-options hidden"}>
        <ul>
          {props.subpages.map((subpage) => {
            return (
              <li key={subpage.text}>
                <NavLink to={subpage.slug}>{subpage.text}</NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

DropdownNavLink.defaultProps = {
  subpages: [],
};

export default DropdownNavLink;
