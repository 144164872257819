import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import ErrorPage from "../pages/ErrorPage";
import News from "../pages/other/News";
import Staff from "../pages/other/Staff";
import StaffApplication from "../pages/other/StaffApplication";
import ServicesDetail from "../pages/services/ServicesDetail";
import ServicesLanding from "../pages/services/ServicesLanding";
import ContactLanding from "../pages/contact/ContactLanding";
import BookTour from "../pages/contact/BookTour";
import StaffLookup from "../pages/contact/StaffLookup";
import AccommodationsLanding from "../pages/accommodations/AccommodationsLanding";
import AccommodationsDetail from "../pages/accommodations/AccommodationsDetail";
import ApplicationProcess from "../pages/accommodations/ApplicationProcess";
import GetInvolvedLanding from "../pages/get_involved/GetInvolvedLanding";
import Donate from "../pages/get_involved/Donate";
import SuccessfulDonation from "../pages/get_involved/SuccessfulDonation";
import FailedDonation from "../pages/get_involved/FailedDonation";
import GolfTournament from "../pages/get_involved/GolfTournament";
import DonorPage from "../pages/get_involved/DonorPage";
import Volunteer from "../pages/get_involved/Volunteer";

import SuccessfulMessage from "../pages/contact/SuccessfulMessage";
import History from "../pages/other/History";
import NewsPost from "../pages/other/NewsPost";
import ScrollToTop from "./ScrollToTop";
import BoardMembers from "../pages/get_involved/BoardMembers";
import TVGraphic from "../TVGraphic";
import TVMenuGraphic from "../TVMenuGraphic";
import TVActivityGraphic from "../TVActivityGraphic";
import SubPage from "../pages/SubPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:slug" element={<NewsPost />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/staff/apply" element={<StaffApplication />} />
          <Route path="/history" element={<History />} />
          <Route path="/contact" element={<ContactLanding />} />
          <Route path="/contact/book-tour" element={<BookTour />} />
          <Route path="/contact/staff" element={<StaffLookup />} />
          <Route path="/contact/successful-message" element={<SuccessfulMessage />} />
          <Route path="/accommodations" element={<AccommodationsLanding />} />
          <Route path="/accommodations/:slug" element={<AccommodationsDetail />} />
          <Route path="/accommodations/apply" element={<ApplicationProcess />} />
          <Route path="/services" element={<ServicesLanding />} />
          <Route path="/services/:slug" element={<ServicesDetail />} />
          <Route path="/getinvolved" element={<GetInvolvedLanding />} />
          <Route path="/getinvolved/donate" element={<Donate />} />
          <Route path="/donate" element={<Navigate replace to="/getinvolved/donate" />} />
          <Route path="/getinvolved/donate/success" element={<SuccessfulDonation />} />
          <Route path="/getinvolved/donate/failed" element={<FailedDonation />} />
          <Route path="/getinvolved/donors" element={<DonorPage />} />
          <Route path="/getinvolved/volunteer" element={<Volunteer />} />
          <Route path="/getinvolved/golf" element={<GolfTournament />} />
          <Route path="/getinvolved/board-of-directors" element={<BoardMembers />} />
          <Route path="/tvgraphic" element={<TVGraphic />} />
          <Route path="/tvmenugraphic/:retirementhome" element={<TVMenuGraphic />} />
          <Route path="/tvactivitygraphic/:home" element={<TVActivityGraphic />} />
          {/* <Route path="/:id" element={<SubPage />} /> */}
        
          <Route path="*" element={<SubPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;
