import React from 'react'
import Page from '../../components/page_components/Page'

export default function SuccessfulDonation() {
  return (
    <Page sidebarType='getInvolved'>
        <h2 style={{textAlign: 'center'}}>Thank you for your donation!</h2>
    </Page>
  )
}
