import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './css/TVMenuGraphic.css';
import Clock from './Clock';
import MenuCard from './MenuCard';
import {Helmet} from "react-helmet";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function TVMenuGraphic() {
    const slideshowInterval = 180000; // in ms, how often the background image changes

    const [slideshowImgs, setSlideshowImgs] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState(1);
    const [todayMenu, setTodayMenu] = useState(null);
    const [tomorrowMenu, setTomorrowMenu] = useState(null);

    const today = new Date();
    const tomorrow = new Date(new Date(today).setDate(today.getDate() + 1));

    const { retirementhome } = useParams();

    const fetchMenus = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/${retirementhome}-menu`)
        .then(response => response.json())
        .then(data => {
            const menuDate = new Date(`${data.startDate}T00:00`);
            const todayDate = new Date(new Date().toLocaleDateString());
            const differenceInDays = (todayDate.getTime() - menuDate.getTime()) / (1000 * 60 * 60 * 24);

            setTodayMenu(data.schedule2.at(differenceInDays % data.schedule2.length));
            setTomorrowMenu(data.schedule2.at((differenceInDays + 1) % data.schedule2.length));
        })
        .catch(err => {
            console.error('Failed to load menus:', err);
        });
    };

    const fetchSlideshowImages = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/tv-graphic`)
            .then((response) => response.json())
            .then((data) => {
                setSlideshowImgs(data.Slideshow);
            })
            .catch((err) => {
                console.error('Failed to load slideshow images:', err);
            });
    };

    // Visually update with next day cards 
    const scheduleMidnightRefresh = () => {
        const now = dayjs().tz("America/Toronto");
        // Add an extra minute so Strapi has time to update menu reset dates
        const nextMidnight = dayjs().tz("America/Toronto").endOf('day').add(1, 'millisecond').add(1, 'minute');
        const timeUntilMidnight = nextMidnight.diff(now);

        setTimeout(() => {
            window.location.reload();
        }, timeUntilMidnight);
    };

    useEffect(() => {
        fetchSlideshowImages();
        fetchMenus();
        scheduleMidnightRefresh();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentPhoto((idx) => (idx === slideshowImgs.length - 1 ? 0 : idx + 1));
        }, slideshowInterval);
        return () => clearTimeout(timer);
    }, [slideshowImgs]);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>

            <div
                className='TVMenuGraphic'
                style={{
                    backgroundImage: slideshowImgs.length > 0 && `url(${slideshowImgs[currentPhoto].url})`,
                    backgroundSize: "cover",
                    transition: "background-image 0.4s ease-in-out",
                    position: "relative",
                }}
            >
                <div className='overlay'></div>
                <div className='topBanner'>
                    <div style={{ width: '170px' }}>
                        <img className='nav-logo' src='/logo_dark.svg' alt='Heidehof Logo' />
                    </div>

                    <div className='title'>
                        <h2>Lunch & Dinner Menu</h2>
                    </div>
                    <div>
                        <Clock />
                    </div>
                </div>
                <div
                    id="preload-images"
                    style={{
                        background: slideshowImgs.map((img) => `url(${img.url}) no-repeat -9999px -9999px`).join(", "),
                    }}
                ></div>

                <div className='menu-items'>
                    {todayMenu &&
                        <MenuCard
                            key={'todayMenu'}
                            day={today.toLocaleString('default', { weekday: 'long' })}
                            date={today.getDate()}
                            month={today.toLocaleString('default', { month: 'long' })}
                            breakfastDescription={todayMenu.breakfast}
                            lunchDescription={todayMenu.lunch}
                            dinnerDescription={todayMenu.dinner}
                            isToday={true}
                            isTomorrow={false}
                        />
                    }
                    {tomorrowMenu &&
                        <MenuCard
                            key={'tomorrowMenu'}
                            day={tomorrow.toLocaleString('default', { weekday: 'long' })}
                            date={tomorrow.getDate()}
                            month={tomorrow.toLocaleString('default', { month: 'long' })}
                            breakfastDescription={tomorrowMenu.breakfast}
                            lunchDescription={tomorrowMenu.lunch}
                            dinnerDescription={tomorrowMenu.dinner}
                            isToday={false}
                            isTomorrow={true}
                        />
                    }
                </div>
            </div>
        </>
    );
}
