import React from "react";
import Page from "../../components/page_components/Page";

export default function FailedDonation() {
  return (
    <Page sidebarType="getInvolved">
      <h2 style={{ textAlign: "center" }}>Sorry, we failed to recieve your donation. Please call (905) 935-3344</h2>
    </Page>
  );
}
