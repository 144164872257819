import { FETCH_EVENTS } from "../actions/events";

const initialState = {
  events: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        events: action.data,
      };

    default:
      return state;
  }
};
