// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TVMenuGraphic {
    height: 100vh;
    width: 100vw;
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.TVMenuGraphic .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.35);
    z-index: 1;
}

.TVMenuGraphic .topBanner {
    background-color: var(--light);
    padding: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position:relative;
    z-index: 2;
}

.TVMenuGraphic .topBanner .time {
    text-align: right;
}

.TVMenuGraphic .topBanner h1 {
    text-align: center;
}

.TVMenuGraphic .topBanner h2 {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 0;
}

.TVMenuGraphic .topBanner p {
    font-size: 1em;
    margin-top: 0;
    margin-bottom: 2px;
}

.TVMenuGraphic .menu-items {
    display: flex;
    flex: 1 1;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 32px;
    margin: 32px;
    position: relative;
    z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/css/TVMenuGraphic.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,2CAA2C;IAC3C,UAAU;AACd;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,SAAO;IACP,iBAAiB;IACjB,uBAAuB;IACvB,SAAS;IACT,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".TVMenuGraphic {\n    height: 100vh;\n    width: 100vw;\n    margin: 0;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n\n.TVMenuGraphic .overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(255, 255, 255, 0.35);\n    z-index: 1;\n}\n\n.TVMenuGraphic .topBanner {\n    background-color: var(--light);\n    padding: 2em;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    position:relative;\n    z-index: 2;\n}\n\n.TVMenuGraphic .topBanner .time {\n    text-align: right;\n}\n\n.TVMenuGraphic .topBanner h1 {\n    text-align: center;\n}\n\n.TVMenuGraphic .topBanner h2 {\n    font-size: 2em;\n    margin-top: 0;\n    margin-bottom: 0;\n}\n\n.TVMenuGraphic .topBanner p {\n    font-size: 1em;\n    margin-top: 0;\n    margin-bottom: 2px;\n}\n\n.TVMenuGraphic .menu-items {\n    display: flex;\n    flex: 1;\n    flex-wrap: nowrap;\n    justify-content: center;\n    gap: 32px;\n    margin: 32px;\n    position: relative;\n    z-index: 3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
