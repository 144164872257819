import axios from "axios";
import qs from "qs";
export const FETCH_STAFF_MEMBERS = "FETCH_STAFF_MEMBERS";
export const FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS";

export const fetchStaffMembers = () => {
  return async (dispatch) => {
    try {
      // build query
      const query = qs.stringify({
        // _where: [{ _or: [{ "personalInfo.firstName": search }, { "personalInfo.lastName": search }, { "personalInfo.title": search }] }, { department: department }],
        // _where: [{ "personalInfo.firstName": search }, { department: department }],
        // _where: [{ department: department }],
        // _start: start,
        // _limit: limit,
      });

      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/staff-members?${query}`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_STAFF_MEMBERS,
        staff: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};
export const fetchDepartments = () => {
  return async (dispatch) => {
    try {
      // make request
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_URL}/departments`,
      };

      // handle request
      const response = await axios(config);
      if (response.status !== 200) {
        let message = "Something went wrong!";
        throw new Error(message);
      }

      //   dispatch action
      dispatch({
        type: FETCH_DEPARTMENTS,
        departments: response.data,
      });
    } catch (err) {
      console.log("error");
      throw err;
    }
  };
};
