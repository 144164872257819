import React from 'react'
import Page from '../../components/page_components/Page'

export default function SuccessfulMessage() {
  return (
    <Page sidebarType='contact'>
        <h2 style={{textAlign: 'center'}}>Thank you for your message! We will get back to you as soon as possible.</h2>
    </Page>
  )
}
