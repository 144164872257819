import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContactCard from "../../components/cards/ContactCard";
import ContactForm from "../../components/forms/ContactForm";
import Page from "../../components/page_components/Page";
import StaffContactBanner from "../../components/StaffContactBanner";
import { v4 as uuid } from "uuid";
import PageHeader from "../../components/page_components/PageHeader";

export default function ContactLanding() {
  const { staff } = useSelector((state) => state.staff);
  const [data, setData] = useState();

  const { contactPage } = useSelector((state) => state.cms);
  let cms = contactPage;

  useEffect(() => {
    if (staff) {
      let tmp = [];
      // select the data for this page based on the slugs
      staff.forEach((element) => {
        let slugs =
          element.contactCtaSlug &&
          element.contactCtaSlug.filter(
            (el) => el.text === window.location.pathname || el.text === "*"
          );
        if (slugs.length >= 1) tmp.push(element);
      });
      setData(tmp);
    }
  }, [staff]);

  if (!cms) return null;

  return (
    <Page sidebarType="contact">
      <div>
        <div style={{textAlign: 'left' ,margin: '0 auto', maxWidth: '800px'}}>
          <h1 className="eb">Contact Info</h1>
          <p><i className="fa-solid fa-location-dot" style={{marginRight: '0.5em'}}></i>600 Lake Street, St. Catharines, Ontario, L2N4J4</p>
          <p><i className="fa-solid fa-envelope" style={{marginRight: '0.5em'}}></i>office@heidehof.com</p>
          <p><i className="fa-solid fa-phone" style={{marginRight: '0.5em'}}></i>(905) 935-3344</p>
          <p><i className="fa-solid fa-clock" style={{marginRight: '0.5em'}}></i>Mondays to Fridays, 8:30 a.m. to 4:30 p.m. 
          On Lunch 12:30 p.m. to 1:00 p.m.</p>
        </div>
        <div style={{maxWidth: '800px', margin: 'auto'}}>
          <h2 className="eb" style={{fontSize: '2em'}}>Leave a Message</h2>
        </div>
        <ContactForm
          name={cms.contactPageNameLabel}
          namePlaceholder={cms.contactPageNamePlaceholder}
          email={cms.contactPageEmailLabel}
          emailPlaceholder={cms.contactPageEmailPlaceholder}
          message={cms.contactPageMessageLabel}
          messagePlaceholder={cms.contactPageLabelPlaceholder}
          buttonLeft={
            cms.contactPageSubmitButton.iconLeft &&
            cms.contactPageSubmitButton.iconLeft.url
          }
          buttonRight={
            cms.contactPageSubmitButton.iconRight &&
            cms.contactPageSubmitButton.iconRight.url
          }
          buttonText={cms.contactPageSubmitButton.buttonText}
        />
      </div>
    </Page>
  );
}
