import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import "../../css/StaffApplication.css";
import Page from "../../components/page_components/Page";
import Alert from "../../components/Alert";
import axios from "axios";
import { flushSync } from "react-dom";
import PageHeader from "../../components/page_components/PageHeader";
import { useSelector } from "react-redux";
import Banner from "../../components/Banner";

export default function StaffApplication() {
  const [alert, setAlert] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [files, setFiles] = useState({
    resume: null,
    coverLetter: null,
  });

  const [fileNames, setFileNames] = useState({
    resume: "No file selected",
    coverLetter: "No file selected",
  });

  const [fileTypes, setFileTypes] = useState({
    resume: "None",
    coverLetter: "None",
  });

  const { staffApplicationPage } = useSelector((state) => state.cms);
  let cms = staffApplicationPage;
  if (!cms) return null;

  const onChange = (e) => {
    /* 
    Force alert component to re-render with an empty message.  
    Without flushSync, React automatically batches the setAlert("") 
    and setAlert("message here"), causing the isOpen state inside 
    message never to change, when the previous message is the same 
    as the current message.

    tldr - without using flushSync, closing an alert won't work properly 
    if the same error message appears twice in a row
    https://github.com/reactwg/react-18/discussions/21
    */
    flushSync(() => {
      setAlert("");
    });
    if (e.target.id === "resumeFile") {
      setFiles((prevState) => ({
        ...prevState,
        resume: e.target.files[0],
      }));
      setFileNames((prevState) => ({
        ...prevState,
        resume: e.target.files[0].name,
      }));
      setFileTypes((prevState) => ({
        ...prevState,
        resume: e.target.files[0].type,
      }));
      if (e.target.files[0].size > 10000000) {
        flushSync(() => {
          setAlert("Your resume file is too large.  Please submit a PDF file less than 10mb.");
        });
        return;
      }
    }
    if (e.target.id === "coverLetterFile") {
      setFiles((prevState) => ({
        ...prevState,
        coverLetter: e.target.files[0],
      }));
      setFileNames((prevState) => ({
        ...prevState,
        coverLetter: e.target.files[0].name,
      }));
      setFileTypes((prevState) => ({
        ...prevState,
        coverLetter: e.target.files[0].type,
      }));
      if (e.target.files[0].size > 10000000) {
        flushSync(() => {
          setAlert("Your cover letter file is too large.  Please submit a PDF file less than 10mb.");
        });
        return;
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let formData = new FormData(form);

    // if(files.resume)

    files.resume && formData.append("resume", [files.resume]);
    files.coverLetter && formData.append("coverLetter", [files.coverLetter]);

    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/staff-application-form`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setShowBanner(true);

      // setAlert(res.data.msg);
      setTimeout(() => {
        window.location = "/";
      }, 4000);
    } catch (err) {
      if (err.response.status === 500) {
        setAlert("Sorry! There was a problem with our server.");
      } else if (err.response.status === 400) {
        setAlert(err.response.data.msg);
      } else {
        setAlert(err.response.data.msg);
      }
    }
  };

  return (
    <Page>
      <form className="Form" onSubmit={onSubmit}>
        <Banner isVisible={showBanner} setIsVisible={setShowBanner} message="Thanks for your Application!" />
        <PageHeader text={cms.pageTitle} />
        <div className="inputWrapper">
          <Alert message={alert} />
          <label htmlFor="name">{cms.nameLabel}</label>
          <input className="textInput" type="text" name="name" id="name" placeholder={cms.namePlaceholder} />

          <label htmlFor="email">{cms.emailLabel}</label>
          <input className="textInput" type="email" name="email" id="email" placeholder={cms.emailPlaceholder} />

          <label htmlFor="phone">{cms.phoneLabel}</label>
          <input className="textInput" type="text" name="phone" id="phone" placeholder={cms.phonePlaceholder} />

          <p style={{ fontWeight: "600", alignSelf: "start" }}>Upload your resume and cover letter</p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "start",
              marginBottom: "32px",
            }}
          >
            <input type="file" id="resumeFile" name="resumeFile" onChange={onChange} />
            <label htmlFor="resumeFile">
              {cms.resumeButton}
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
            </label>
            <div style={{ marginBottom: "16px" }}>
              <p className="fileName" style={{ marginBottom: "0" }}>
                {fileNames.resume}
              </p>
              {ErrorMessage(fileTypes.resume)}
            </div>

            <input type="file" id="coverLetterFile" name="coverLetterFile" onChange={onChange} />
            <label htmlFor="coverLetterFile">
              {cms.coverLetterButton}
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
            </label>
            <div>
              <p className="fileName" style={{ marginBottom: "4px" }}>
                {fileNames.coverLetter}
              </p>
              {ErrorMessage(fileTypes.coverLetter)}
            </div>
          </div>

          <Button>
            {cms.submitButton.buttonText}
            <img src={cms.submitButton.iconRight && cms.submitButton.iconRight.url} style={{ height: 18, marginLeft: 8 }} className="iconSvg" />
          </Button>
        </div>
      </form>
    </Page>
  );
}

const ErrorMessage = (fileType) => {
  return (
    <>
      {fileType === "application/pdf" && (
        <>
          <i className="fa-solid fa-circle-check" style={{ color: "green" }}></i>
        </>
      )}
      {fileType !== "application/pdf" && fileType !== "None" && (
        <>
          <i className="fa-solid fa-circle-exclamation" style={{ color: "var(--gold)" }}></i>
          <p style={{ margin: "0 auto", fontSize: "0.8em", color: "grey" }}>If possible, please upload this as a PDF</p>
        </>
      )}
    </>
  );
};
