import React, { useState, useEffect } from "react";
import RichText from "../../components/RichText";
import { useParams } from "react-router-dom";
import "../../css/News.css";
import Page from "../../components/page_components/Page";
import { useSelector } from "react-redux";
import { formatDate } from "../../util/util";

export default function NewsPost(props) {
  const { articles } = useSelector((state) => state.article);

  const [content, setContent] = useState();
  const { slug } = useParams();

  // eventually replace this with strapi stuff
  useEffect(() => {
    let tmp = {};
    if (articles) tmp = articles.filter((el) => el.slug === slug)[0];
    setContent(tmp);
  }, [articles, slug]);

  if (!content) return null;
  return (
    <Page>
      <div className="NewsPost">
        <h1 className="eb">{content.title}</h1>
        <p>{formatDate(content.date)}</p>
        <img src={content.featuredImage && content.featuredImage.url} alt={content.featuredImage && content.featuredImage.alt} />
        <RichText text={content.body} />
      </div>
    </Page>
  );
}
