import { FETCH_ARTICLES } from "../actions/articles";

const initialState = {
  articles: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES:
      return {
        ...state,
        articles: action.data,
      };

    default:
      return state;
  }
};
