// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DonorBoard {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
}

.Donor {
    display: flex;
    gap: 8px;
    border-radius: 6px;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    align-items: center;
}

.Donor.recent, .Donor.benefactor {
    padding: 8px 32px;
}

.Donor.patron {
    padding: 0px 16px;
    font-size: 0.9em;
}

.Donor.friend {
    padding: 4px 8px;
    font-size: 0.8em;
}

.Donor.friend p {
    margin: 0;
}


@media (min-width: 1200px) {
    .DonorBoard {
        grid-template-columns: repeat(3, 1fr);
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/DonorBoard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,kBAAkB;IAClB,8BAA8B;IAC9B,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,SAAS;AACb;;;AAGA;IACI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".DonorBoard {\n    display: flex;\n    gap: 8px;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.Donor {\n    display: flex;\n    gap: 8px;\n    border-radius: 6px;\n    background-color: var(--white);\n    box-shadow: var(--box-shadow);\n    align-items: center;\n}\n\n.Donor.recent, .Donor.benefactor {\n    padding: 8px 32px;\n}\n\n.Donor.patron {\n    padding: 0px 16px;\n    font-size: 0.9em;\n}\n\n.Donor.friend {\n    padding: 4px 8px;\n    font-size: 0.8em;\n}\n\n.Donor.friend p {\n    margin: 0;\n}\n\n\n@media (min-width: 1200px) {\n    .DonorBoard {\n        grid-template-columns: repeat(3, 1fr);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
