// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* for other selectors, go to ActivityCard.css for universal styles */

.today-activity-card {
    width: 500px;
}

.today-activity-card .activity-card-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 16px;
    padding: 32px;
    gap: 32px;
    border-radius: 4px;
    background-color: var(--light);
    color: #10310F;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.today-activity-card .current-activity-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;    
    background-color: #10310F;
    border-radius: 4px;
}

.activity-block {
    background-color: var(--light);
    border-radius: 4px;
}

.activity-name {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
}

.today-activity-card .activity {

}

.today-activity-card .current-activity {
    color: white;
    
}

.today-activity-card .activity-time {
    color: #10310F;
}

.today-activity-card .current-activity-time {
    color: white;
}

.today-activity-card .activity-section {
    gap: 16px;
}

.hide-label {
    display: none;
}

.show-label {
    display: initial;
    color: white;
    font-weight:800;
}

.details {
    display: flex;
    flex-wrap: nowrap;
    gap: 32px;
}`, "",{"version":3,"sources":["webpack://./src/css/TodayActivityCard.css"],"names":[],"mappings":"AAAA,qEAAqE;;AAErE;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,8BAA8B;IAC9B,cAAc;IACd,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,aAAa;IACb,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;;AAEA;;AAEA;IACI,YAAY;;AAEhB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,SAAS;AACb","sourcesContent":["/* for other selectors, go to ActivityCard.css for universal styles */\n\n.today-activity-card {\n    width: 500px;\n}\n\n.today-activity-card .activity-card-header {\n    display: flex;\n    flex-wrap: nowrap;\n    align-items: center;\n    margin-bottom: 16px;\n    padding: 32px;\n    gap: 32px;\n    border-radius: 4px;\n    background-color: var(--light);\n    color: #10310F;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n}\n\n.today-activity-card .current-activity-block {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    padding: 32px;    \n    background-color: #10310F;\n    border-radius: 4px;\n}\n\n.activity-block {\n    background-color: var(--light);\n    border-radius: 4px;\n}\n\n.activity-name {\n    white-space: pre-wrap;\n    overflow-wrap: anywhere;\n}\n\n.today-activity-card .activity {\n\n}\n\n.today-activity-card .current-activity {\n    color: white;\n    \n}\n\n.today-activity-card .activity-time {\n    color: #10310F;\n}\n\n.today-activity-card .current-activity-time {\n    color: white;\n}\n\n.today-activity-card .activity-section {\n    gap: 16px;\n}\n\n.hide-label {\n    display: none;\n}\n\n.show-label {\n    display: initial;\n    color: white;\n    font-weight:800;\n}\n\n.details {\n    display: flex;\n    flex-wrap: nowrap;\n    gap: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
