// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-card {
    height: auto;
    width: 300px;
    padding: 0px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(255,255,255,0.95);
    text-transform: capitalize;
}

.menu-card .menu-card-header {
    color: #445A22;
}

.menu-card .menu-title, .menu-card .menu-description {
    color: #10310F;
}

.today-card {
    background-color: #10310F;
}

.today-card .menu-card-header, .today-card .menu-title, .today-card .menu-description {
    color: white;
}

.today-card .divider {
    background-color: white;
}

.menu-card-header {
    text-align: left;
    margin: 32px;
}

.day {
    margin-bottom: 16px;
}

.day, .month {
    font-size: 20px;
}

.date {
    font-size: 48px;
    font-weight: bold;
}

.menu-section {
    margin: 32px;
}

.menu-title {
    font-size: 24px;
    margin-bottom: 16px;
}

.menu-description {
    font-size: 20px;
}

.divider {
    border: 0;
    height: 1px;
    margin-left: 32px;
    margin-right: 32px;
    background-color: #10310F;
}`, "",{"version":3,"sources":["webpack://./src/css/MenuCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,wCAAwC;IACxC,wCAAwC;IACxC,0BAA0B;AAC9B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".menu-card {\n    height: auto;\n    width: 300px;\n    padding: 0px;\n    border-radius: 4px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    background-color: rgba(255,255,255,0.95);\n    text-transform: capitalize;\n}\n\n.menu-card .menu-card-header {\n    color: #445A22;\n}\n\n.menu-card .menu-title, .menu-card .menu-description {\n    color: #10310F;\n}\n\n.today-card {\n    background-color: #10310F;\n}\n\n.today-card .menu-card-header, .today-card .menu-title, .today-card .menu-description {\n    color: white;\n}\n\n.today-card .divider {\n    background-color: white;\n}\n\n.menu-card-header {\n    text-align: left;\n    margin: 32px;\n}\n\n.day {\n    margin-bottom: 16px;\n}\n\n.day, .month {\n    font-size: 20px;\n}\n\n.date {\n    font-size: 48px;\n    font-weight: bold;\n}\n\n.menu-section {\n    margin: 32px;\n}\n\n.menu-title {\n    font-size: 24px;\n    margin-bottom: 16px;\n}\n\n.menu-description {\n    font-size: 20px;\n}\n\n.divider {\n    border: 0;\n    height: 1px;\n    margin-left: 32px;\n    margin-right: 32px;\n    background-color: #10310F;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
