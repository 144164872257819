import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './css/TVActivityGraphic.css';
import Clock from './Clock';
import ActivityCard from './ActivityCard';
import TodayActivityCard from './TodayActivityCard';
import {Helmet} from "react-helmet";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Toronto");

export default function TVActivityGraphic() {
    const { home } = useParams();
    const [activities, setActivities] = useState([]);
    const [slideshowImgs, setSlideshowImgs] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState(0);

    // Used for formatting URL
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };
    const homeCapitalized = capitalizeFirstLetter(home);

    const slideshowInterval = 180000; // in ms, how often the background image changes

    const processActivities = (data) => {
        const now = dayjs().tz("America/Toronto");
        const activitiesByDate = data.reduce((acc, activity, index, array) => {
            const activityDateTime = dayjs(`${activity.Date}T${activity.ActivityTime}`).tz("America/Toronto");
            const dateKey = activityDateTime.format('YYYY-MM-DD');
            const timeKey = activityDateTime.format('HH:mm');

            if (!acc[dateKey]) {
                acc[dateKey] = {};
            }

            if (!acc[dateKey][timeKey]) {
                acc[dateKey][timeKey] = [];
            }

            // Variables for determining "Happening Now" visual
            const nextActivity = array.find((item, idx) => idx > index && dayjs(`${item.Date}T${item.ActivityTime}`).tz("America/Toronto").isAfter(activityDateTime));
            const nextActivityTime = nextActivity ? dayjs(`${nextActivity.Date}T${nextActivity.ActivityTime}`).tz("America/Toronto") : null;

            const isCurrent = activityDateTime.isBefore(now) && (
                (nextActivityTime && now.isBefore(nextActivityTime)) ||
                activityDateTime.add(1, 'hour').isAfter(now)
            );
            const isPast = !isCurrent && activityDateTime.isBefore(now);

            acc[dateKey][timeKey].push({
                time: activityDateTime.format('h:mm A'),
                name: activity.ActivityName,
                isCurrent: isCurrent && !isPast,
                isPast: isPast,
                isFuture: activityDateTime.isAfter(now),
            });

            return acc;
        }, {});

        // Sort times for each date
        Object.keys(activitiesByDate).forEach(date => {
            activitiesByDate[date] = Object.keys(activitiesByDate[date])
                .sort()
                .reduce((sorted, time) => {
                    sorted[time] = activitiesByDate[date][time];
                    return sorted;
                }, {});
        });

        return activitiesByDate;
    };

    // Fetch today's & next 3 days' activities 
    const fetchActivities = () => {
        const startDate = dayjs().tz("America/Toronto").format('YYYY-MM-DD');
        const endDate = dayjs().tz("America/Toronto").add(3, 'days').format('YYYY-MM-DD');

        fetch(`${process.env.REACT_APP_BACKEND_URL}/activities?Date_gte=${startDate}&Date_lte=${endDate}&Home=${homeCapitalized}`)
            .then(response => response.json())
            .then(data => {
                const processedActivities = processActivities(data);
                setActivities(processedActivities);
            })
            .catch(err => console.error('Failed to load activities:', err));
    };

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/tv-graphic`)
            .then(response => response.json())
            .then(data => {
                setSlideshowImgs(data.Slideshow);
            })
            .catch(err => console.error('Failed to load slideshow images:', err));
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentPhoto((prev) => (prev + 1) % slideshowImgs.length);
        }, slideshowInterval);
        return () => clearInterval(timer);
    }, [slideshowImgs]);

    // Refresh page contents to reflect with actual activity times
    useEffect(() => {
        fetchActivities();

        const now = dayjs().tz("America/Toronto");
        const nextQuarterHour = now.add(15 - (now.minute() % 15), 'minute').startOf('minute');
        const timeUntilNextQuarterHour = nextQuarterHour.diff(now);

        const refreshAtQuarterHour = () => {
            fetchActivities();
            const intervalId = setInterval(fetchActivities, 15 * 60 * 1000);
            return () => clearInterval(intervalId);
        };

        const timeoutId = setTimeout(() => {
            refreshAtQuarterHour();
        }, timeUntilNextQuarterHour);

        return () => {
            clearTimeout(timeoutId);
            refreshAtQuarterHour();
        };
    }, [home]);

    // Update ongoing and finished activities
    useEffect(() => {
        const intervalId = setInterval(() => {
            setActivities((prevActivities) => {
                const now = dayjs().tz("America/Toronto");
                const updatedActivities = { ...prevActivities };
                Object.keys(updatedActivities).forEach(date => {
                    Object.keys(updatedActivities[date]).forEach(time => {
                        updatedActivities[date][time] = updatedActivities[date][time].map(activity => {
                            const activityDateTime = dayjs(`${date}T${time}`).tz("America/Toronto");
                            const nextActivityTime = dayjs(`${date}T${
                                // Times are grouped & sorted by this point; grab first time that is after current activity's time
                                Object.keys(updatedActivities[date]).find(otherTime => 
                                    dayjs(`${date}T${otherTime}`).isAfter(dayjs(`${date}T${time}`))
                                )
                            }`).tz("America/Toronto");
                            const isCurrent = activityDateTime.isBefore(now) && (
                                (nextActivityTime && now.isBefore(nextActivityTime)) ||
                                activityDateTime.add(1, 'hour').isAfter(now)
                            );
                            activity.isCurrent = isCurrent;
                            activity.isPast = !isCurrent && activityDateTime.isBefore(now);
                            return activity;
                        }).filter(activity => !activity.isPast);
                    });
                });
                return updatedActivities;
            });
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>

            <div className='TVActivityGraphic' style={{
                backgroundImage: slideshowImgs.length > 0 ? `url(${slideshowImgs[currentPhoto].url})` : 'none',
                backgroundSize: "cover",
                transition: "background-image 0.4s ease-in-out",
                position: "relative",
            }}>
                <div className='overlay'></div>
                <div className='topBanner'>
                    <div style={{ width: '170px' }}>
                        <img className='nav-logo' src='/logo_dark.svg' alt='Heidehof Logo' />
                    </div>
                    <h1>Activity Schedule</h1>
                    <Clock />
                </div>
                <div className='activity-schedule'>
                    {Object.entries(activities).map(([date, times], index) => {
                        const isTodayDate = dayjs(date).isSame(dayjs(), 'day');
                        const isTomorrowDate = dayjs(date).isSame(dayjs().add(1, 'day'), 'day');
                        const ActivityComponent = isTodayDate ? TodayActivityCard : ActivityCard;

                        const dayActivities = isTodayDate ? [].concat(...Object.values(times)) : Object.values(times).map(timeBlock => timeBlock);
                        console.log("Processed Activities for Today: ", dayActivities);

                        let dayLabel;
                        if (isTodayDate) {
                            dayLabel = "Today";
                        } else if (isTomorrowDate) {
                            dayLabel = "Tomorrow";
                        } else {
                            dayLabel = dayjs(date).format('dddd');
                        }

                        return (
                            <ActivityComponent
                                key={index}
                                day={dayLabel}
                                date={dayjs(date).format('D')}
                                month={dayjs(date).format('MMMM')}
                                activities={dayActivities}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
}
