import React, { useState } from "react";
import Button from "../Button";
import "../../css/DonationForm.css";
import PageHeader from "../page_components/PageHeader";
import CountrySelect from "./CountrySelect";

export default function DonationForm(props) {
  const [donationAmount, setDonationAmount] = useState("");
  const [isValidAmount, setIsValidAmount] = useState(false);
  const [isInMemorium, setIsInMemorium] = useState(false);

  const toggleIsInMemorium = () => {
    setIsInMemorium((current) => !current);
  };

  const handleAmountChange = (e) => {
    const parsedValue = parseInt(e.target.value);
    if (parsedValue) {
      setDonationAmount(parsedValue);
      if (parsedValue > 0) {
        setIsValidAmount(true);
      } else {
        setIsValidAmount(false);
      }
    } else {
      setDonationAmount(parsedValue);
      setIsValidAmount(false);
    }
  };

  const handleSetDonationAmount = (e, amount) => {
    e.preventDefault();
    setDonationAmount(amount);
    setIsValidAmount(true);
  };

  // article for using form data in JSON post request -
  // https://simonplend.com/how-to-use-fetch-to-post-form-data-as-json-to-your-api/
  // Code based on this tutorial
  // https://www.youtube.com/watch?v=1r-F3FIONl8

  const donate = (evt) => {
    evt.preventDefault();
    const form = evt.currentTarget;
    let formData = new FormData(form);
    !formData.get("donorName") && formData.set("donorName", "Anonymous");
    const plainFormData = Object.fromEntries(formData.entries());

    console.log(plainFormData);

    fetch(process.env.REACT_APP_BACKEND_URL + "/stripe/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        donation: { amount: donationAmount, quantity: 1 },
        donationInfo: plainFormData,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      .then(({ session }) => {
        console.log(session);
        window.location = `${session}`;
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
    <form id="donationForm" className="rounded" onSubmit={(evt) => donate(evt)}>
      <PageHeader text={props.cms.donatePageTitle} style={{ textAlign: "center" }} />

      <label htmlFor="donorName">{props.cms.donatePageNameLabel}</label>
      <input className="textInput" type="text" name="donorName" id="donorName" placeholder={props.cms.donatePageNamePlaceholder} />

      <label htmlFor="donorEmail">{props.cms.donatePageEmailLabel}</label>
      <input className="textInput" type="email" name="donorEmail" id="donorEmail" placeholder={props.cms.donatePageEmailPlaceholder} required />

      <label>Address</label>
      <span className="subtext">Required for donation receipt</span>
      <input className="textInput" name="donorAddress1" id="donorAddress1" placeholder="Address line one" required />
      <input className="textInput" name="donorAddress2" id="donorAddress2" placeholder="Address line two" />
      <div className="inputRow">
        <input className="textInput" name="donorCity" id="donorCity" placeholder="City" required />
        <input className="textInput" name="donorPostal" id="donorPostal" placeholder="Postal Code" required />
      </div>
      <CountrySelect />

      <label htmlFor="donationAmount">{props.cms.donatePageAmountLabel}</label>

      <div style={styles.amountButtonsWrapper}>
        <Button extraStyles={styles.amountButton} light={true} onClick={(evt) => handleSetDonationAmount(evt, 20)}>
          $20
        </Button>
        <Button extraStyles={styles.amountButton} light={true} onClick={(evt) => handleSetDonationAmount(evt, 50)}>
          $50
        </Button>
        <Button extraStyles={styles.amountButton} light={true} onClick={(evt) => handleSetDonationAmount(evt, 100)}>
          $100
        </Button>
        <Button extraStyles={styles.amountButton} light={true} onClick={(evt) => handleSetDonationAmount(evt, 500)}>
          $500
        </Button>
        <Button extraStyles={styles.amountButton} light={true} onClick={(evt) => handleSetDonationAmount(evt, 1000)}>
          $1000
        </Button>
        <div>
          {/* Currency Input */}
          <span className="currencyInput">
            ${" "}
            <input
              type="number"
              min="1"
              step="1"
              max={props.maximumDonation}
              onInput={handleAmountChange}
              value={donationAmount.toString()}
              className="textInput"
              name="donationAmount"
              placeholder="10"
              id="donationAmount"
              required
            />
            .00
            {isValidAmount && <i className="fa-solid fa-circle-check" style={{ color: "green", marginLeft: "8px" }}></i>}
          </span>
        </div>
      </div>

      <br />

      {!isValidAmount && donationAmount !== "" && (
        <>
          <small style={styles.warningText}>Please enter an amount in number of dollars or select a preset amount. Do not use commas</small>
        </>
      )}

      <input onClick={() => toggleIsInMemorium()} type="checkbox" name="isInMemorium" id="isInMemorium" />
      <label className="checkboxLabel" htmlFor="isInMemorium">
        Donate in Memorium
      </label>

      {isInMemorium && (
        <>
          <label htmlFor="inMemoriumOf">In memorium of</label>
          <input className="textInput" type="text" name="isInMemoriumOf" id="isInMemoriumOf" />
        </>
      )}

      <label htmlFor="donorMessage"> {props.cms.donatePageMessageLabel}</label>
      <textarea className="textInput" name="donorMessage" id="donorMessage" cols="50" rows="6" placeholder={props.cms.donatePageMessagePlaceholder}></textarea>

      <Button className="submitDonation">
        {props.cms.donatePageSubmitButton.iconLeft && <img src={props.cms.donatePageSubmitButton.iconLeft.url} style={{ width: 15, marginRight: "8px" }} className="iconSvg" />}
        {props.cms.donatePageSubmitButton.buttonText}

        {props.cms.donatePageSubmitButton.iconRight && <img src={props.cms.donatePageSubmitButton.iconRight.url} style={{ width: 15, marginLeft: "8px" }} className="iconSvg" />}
      </Button>
    </form>
  );
}

const styles = {
  amountButtonsWrapper: {
    display: "inline-flex",
    gap: "8px",
    flexWrap: "wrap",
    margin: "16px 0",
    justifyContent: "center",
  },
  warningText: {
    marginBottom: "8px",
    display: "block",
    color: "red",
    maxWidth: "75%",
  },
  amountButton: {
    padding: "12px",
    position: "relative",
    margin: "0",
    width: "55px",
  },
};
