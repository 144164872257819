// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageWrapper {
  position: relative;
  padding-top: 64px;
  max-width: 100vw;
  display: flex;
}

#pageContent {
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  background-color: var(--white);
  max-width: 100vw;
  overflow: hidden;
}

#pageContent.hasSidebar.shown {
  /* move the page content down so the sidebar toggle button 
  doesn't cover the content at the top of the page */
  max-width: calc(100vw - 300px);
}

#pageContent.hasSidebar.expandable {
  padding-top: 96px;
}

/* Add top padding to account for fixed navbar */

@media (min-width: 950px) {
  #pageContent {
    padding: 48px 64px 64px 64px;
  }
  .pageWrapper {
    padding-top: 72px;
  }
  .Sidebar {
    top: 72px !important;
    height: calc(100vh - 72px) !important;
  }
}

@media (min-width: 1300px) {
  .pageWrapper {
    padding-top: 92px;
  }
  .Sidebar {
    top: 92px !important;
    height: calc(100vh - 92px) !important;
  }
}

/* -------------------------------------------- */
`, "",{"version":3,"sources":["webpack://./src/css/Page.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;oDACkD;EAClD,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA,gDAAgD;;AAEhD;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,iBAAiB;EACnB;EACA;IACE,oBAAoB;IACpB,qCAAqC;EACvC;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,oBAAoB;IACpB,qCAAqC;EACvC;AACF;;AAEA,iDAAiD","sourcesContent":[".pageWrapper {\n  position: relative;\n  padding-top: 64px;\n  max-width: 100vw;\n  display: flex;\n}\n\n#pageContent {\n  min-height: 100vh;\n  width: 100%;\n  padding: 20px;\n  background-color: var(--white);\n  max-width: 100vw;\n  overflow: hidden;\n}\n\n#pageContent.hasSidebar.shown {\n  /* move the page content down so the sidebar toggle button \n  doesn't cover the content at the top of the page */\n  max-width: calc(100vw - 300px);\n}\n\n#pageContent.hasSidebar.expandable {\n  padding-top: 96px;\n}\n\n/* Add top padding to account for fixed navbar */\n\n@media (min-width: 950px) {\n  #pageContent {\n    padding: 48px 64px 64px 64px;\n  }\n  .pageWrapper {\n    padding-top: 72px;\n  }\n  .Sidebar {\n    top: 72px !important;\n    height: calc(100vh - 72px) !important;\n  }\n}\n\n@media (min-width: 1300px) {\n  .pageWrapper {\n    padding-top: 92px;\n  }\n  .Sidebar {\n    top: 92px !important;\n    height: calc(100vh - 92px) !important;\n  }\n}\n\n/* -------------------------------------------- */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
