import React, { Fragment, useState } from "react";
import RichText from "./RichText";

function Accordion({ before, after, items }) {
  const [openIndex, setOpenIndex] = useState(-1);

  const handleClick = (index) => {
    if (openIndex === index) {
      setOpenIndex(-1);
      return;
    }
    setOpenIndex(index);
  };

  return (
    <div
      style={{
        backgroundColor: "var(--light)",
        paddingTop: "32px",
        paddingBottom: "32px",
        marginBottom: "16px",
      }}
    >
      <div className="accordion rounded">
        {items.map((el, idx) => {
          return (
            <Fragment key={idx}>
              <div className="accordionItemHeader" onClick={() => handleClick(idx)}>
                <p>{el.heading}</p>
                <i className="fa-solid fa-plus"></i>
              </div>
              {openIndex === idx && (
                <div className="accordionItemContent">
                  {/* <i className="fa-solid fa-circle-notch spin" style={{ fontSize: "2.5em", position: "absolute", zIndex: "1", top: "47%", left: "47%" }}></i> */}
                  <div className="accordionBody">
                    <RichText text={el.body} />
                    {el.image && el.image.ext === ".pdf" ? <embed src={el.image.url} /> : <img src={el.image.url} />}
                  </div>
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Accordion;
