import React, { useState } from "react";
import "../css/Gallery.css";

export default function Gallery({ images }) {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const setNextImage = (increment) => {
    if (currentImage + increment < 0) {
      setCurrentImage(images.length - 1);
    }
    if (currentImage + increment > images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage((current) => current + increment);
    }
  };

  if (!images) return null;

  return (
    <>
      {/* fullscreen lightbox */}
      {lightboxIsOpen && (
        <>
          <div className="fullscreenOverlay" onClick={() => setLightboxIsOpen(false)}>
            <div className="fullscreenBtn">
              <i className="fa-solid fa-x"></i>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setNextImage(-1);
              }}
              className="lightBoxBtn left"
            >
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setNextImage(1);
              }}
              className="lightBoxBtn right"
            >
              <i className="fa-solid fa-arrow-right"></i>
            </div>
            <img
              key={images[currentImage]?.url}
              src={images[currentImage]?.url}
              className="currentLightboxImage"
              onClick={(e) => {
                e.stopPropagation();
              }}
            ></img>
          </div>
        </>
      )}
      {/* inline gallery */}
      <div className="Gallery" onClick={() => setLightboxIsOpen(true)}>
        <div className="left">
          <div className="imgDiv" style={{ backgroundImage: "url(" + images[0]?.url + ")" }}></div>
        </div>
        <div className="right">
          <div className="rightTop">
            <div className="imgDiv" style={{ backgroundImage: "url(" + images[1]?.url + ")" }}></div>
            <div className="imgDiv" style={{ backgroundImage: "url(" + images[2]?.url + ")" }}></div>
          </div>
          <div className="rightBottom">
            <div className="imgDiv" style={{ backgroundImage: "url(" + images[3]?.url + ")" }}></div>
            <div
              className={images.length <= 5 ? "imgDiv" : "imgDiv more"}
              style={{
                backgroundImage: "url(" + images[4]?.url + ")",
                position: "relative",
              }}
            >
              {images.length > 5 && (
                <div className="rounded overlay">
                  <p>+{images.length - 5}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
