// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceCards {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
}

@media (max-width: 1300px) {
  .serviceCards {
    grid-template-columns: repeat(2, 1fr);
  }
  /* if there are an odd number of cards, make the last card span 2 columns */
  .serviceCards :last-child:not(:nth-child(even)) {
    grid-column: span 2;
  }
}

.serviceCard {
  height: 150px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: var(--light);
  text-align: center;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: transform 0.2s ease 0s;
}

.serviceCard p {
  font-weight: 600;
  max-width: 80%;
  max-height: 30px;
}

.serviceCard:hover {
  transform: scale(1.05);
}

.serviceCard img {
  width: 48px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

`, "",{"version":3,"sources":["webpack://./src/css/ServiceCard.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE;IACE,qCAAqC;EACvC;EACA,2EAA2E;EAC3E;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;EAC7B,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".serviceCards {\n  margin-top: 32px;\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n  gap: 16px;\n}\n\n@media (max-width: 1300px) {\n  .serviceCards {\n    grid-template-columns: repeat(2, 1fr);\n  }\n  /* if there are an odd number of cards, make the last card span 2 columns */\n  .serviceCards :last-child:not(:nth-child(even)) {\n    grid-column: span 2;\n  }\n}\n\n.serviceCard {\n  height: 150px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--light);\n  text-align: center;\n  border-radius: 8px;\n  box-shadow: var(--box-shadow);\n  transition: transform 0.2s ease 0s;\n}\n\n.serviceCard p {\n  font-weight: 600;\n  max-width: 80%;\n  max-height: 30px;\n}\n\n.serviceCard:hover {\n  transform: scale(1.05);\n}\n\n.serviceCard img {\n  width: 48px;\n  aspect-ratio: 1 / 1;\n  object-fit: contain;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
