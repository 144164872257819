import React, { useEffect } from "react";
import "../../css/News.css";
import Page from "../../components/page_components/Page";
import NewsCard from "../../components/cards/NewsCard";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../util/util";
import { v4 as uuid } from "uuid";
import PageHeader from "../../components/page_components/PageHeader";
import * as CmsActions from "../../store/actions/cms";

export default function News() {
  const { articles } = useSelector((state) => state.article);
  const { events } = useSelector((state) => state.event);
  const { newsPage } = useSelector((state) => state.cms);
  let cms = newsPage;
  if (!cms) return null;

  // create a new sorted array instead of mutating the original
  const sortedArticles = articles 
    ? [...articles].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )
    : [];

  return (
    <Page>
      <div className="newsColumns" style={{ position: "relative" }}>
        {/* news section */}
        <div>
          <div className="newsHeader">
            <PageHeader text={cms.pageTitle} />
            <a className="scrollToEvents" href="#events">
              <strong>Upcoming Events</strong>
              <i className="fa-solid fa-arrow-down" style={{ marginLeft: "8px" }}></i>
            </a>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
             {sortedArticles.map((el) => (
              <NewsCard 
                key={uuid()} 
                title={el.title} 
                date={formatDate(el.date)} 
                pinned={el.isPinned} 
                contentSnippet={el.summary} 
                featureImg={el.featuredImage} 
                slug={el.slug} 
              />
            ))}
          </div>
        </div>
        {/* events/history section */}
        <div
          style={{
            position: "sticky",
            top: "84px",
            maxHeight: "calc(100vh-92px)",
          }}
        >
          <div className="events" id="events">
            {events && events.length > 0 && (
              <h2 className="eb" style={{ marginTop: "34px" }}>
                {cms.eventsTitle}
              </h2>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "inherit",
              }}
            >
              {events && events.map((el) => <EventCard key={uuid()} title={el.title} date={formatDate(el.date)} href={`${el.link}`} />) }

              {events && events.length > 0 && <hr style={{ width: "100%", border: "1px solid lightgrey" }} />}
              <div
                className="EventCard"
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  padding: "32px",
                }}
              >
                <p
                  className="eb"
                  style={{
                    marginTop: "8px",
                    fontSize: "1.2em",
                    fontWeight: "600",
                  }}
                >
                  {cms && cms.callToAction && cms.callToAction.mainText}
                </p>
                <Button buttonType="link" href={cms.callToAction.button[0].linkUrl} light={true}>
                  {cms.callToAction.button[0].buttonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export function EventCard(props) {
  return (
    <a href={props.href} target="_blank" className={!props.href ? "disabled" : ""}>
      <div className="EventCard">
        <h3>{props.title}</h3>
        <div style={{ display: "flex", gap: "8px" }}>
          <i style={{ fontSize: "0.9em" }} className="fa-solid fa-calendar-check"></i>
          <small className="eb">{props.date}</small>
        </div>
      </div>
    </a>
  );
}
