// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    border-radius: 8px;
    overflow: hidden;
    background-color: #fffef5;
    border-spacing: 0;
    box-shadow: var(--box-shadow);
    width: 100%;
    color: var(--dark);
}
.table tr:nth-child(even){
    background-color: #fefacd;
}
.table td{
    padding: 16px;
}

.table th {
    text-align: start;
    padding: 8px 16px;
}

.table .header {
    background-color: var(--dark);
}
.table .header .heading span{
    color: white;
    font-size: 1.1em;
    font-weight: 600;
}

/* Mobile table styling */

.MobileRow {
    padding: 16px;
}

/* Pagination */

.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination div{
    color: white;
    background-color: var(--dark);
    font-size: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 200px;
    margin: 0 20px;
}
.pagination span{
    width: 30px;
    text-align: center;
    font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/css/Table.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;IACjB,6BAA6B;IAC7B,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;AACjC;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA,yBAAyB;;AAEzB;IACI,aAAa;AACjB;;AAEA,eAAe;;AAEf;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,6BAA6B;IAC7B,eAAe;IACf,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,oBAAoB;IACpB,cAAc;AAClB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".table {\n    border-radius: 8px;\n    overflow: hidden;\n    background-color: #fffef5;\n    border-spacing: 0;\n    box-shadow: var(--box-shadow);\n    width: 100%;\n    color: var(--dark);\n}\n.table tr:nth-child(even){\n    background-color: #fefacd;\n}\n.table td{\n    padding: 16px;\n}\n\n.table th {\n    text-align: start;\n    padding: 8px 16px;\n}\n\n.table .header {\n    background-color: var(--dark);\n}\n.table .header .heading span{\n    color: white;\n    font-size: 1.1em;\n    font-weight: 600;\n}\n\n/* Mobile table styling */\n\n.MobileRow {\n    padding: 16px;\n}\n\n/* Pagination */\n\n.pagination{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.pagination div{\n    color: white;\n    background-color: var(--dark);\n    font-size: 20px;\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n    border-radius: 200px;\n    margin: 0 20px;\n}\n.pagination span{\n    width: 30px;\n    text-align: center;\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
