// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 570px) {
    .MobileLinkGroup {
        display: none !important;
    }
}

.MobileLinkGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    filter: var(--shadow);
}

.MobileLinkGroup a {
    border: 2px solid var(--dark);
    margin-bottom: -2px; /* overlaps borders */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: var(--light);
}

.MobileLinkGroup a {
    font-weight: 500;
}

.MobileLinkGroup a:hover {
    transform: scale(1.01);
}

.MobileLinkGroup a {
    transition: transform 100ms ease 0s;
}

.MobileLinkGroup a:last-child {
    border-radius: 0 0 6px 6px;
}

.MobileLinkGroup a:first-child {
    border-radius: 6px 6px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/css/MobileLinkGroup.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB,EAAE,qBAAqB;IAC1C,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["@media (min-width: 570px) {\n    .MobileLinkGroup {\n        display: none !important;\n    }\n}\n\n.MobileLinkGroup {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    margin: auto;\n    filter: var(--shadow);\n}\n\n.MobileLinkGroup a {\n    border: 2px solid var(--dark);\n    margin-bottom: -2px; /* overlaps borders */\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px;\n    background-color: var(--light);\n}\n\n.MobileLinkGroup a {\n    font-weight: 500;\n}\n\n.MobileLinkGroup a:hover {\n    transform: scale(1.01);\n}\n\n.MobileLinkGroup a {\n    transition: transform 100ms ease 0s;\n}\n\n.MobileLinkGroup a:last-child {\n    border-radius: 0 0 6px 6px;\n}\n\n.MobileLinkGroup a:first-child {\n    border-radius: 6px 6px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
