import React from "react";

const PageHeader = ({ text, style }) => {
  return (
    <h1 style={style} className="eb">
      {text}
    </h1>
  );
};

export default PageHeader;
