import React from "react";
import { v4 as uuid } from "uuid";

function Tags({ items }) {
  return (
    <div
      style={{
        margin: "16px 0",
        maxWidth: "700px",
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
      }}
    >
      {items.map((el) => {
        return (
          <a key={uuid()} className="eb tagLink lg">
            {el.text}
          </a>
        );
      })}
    </div>
  );
}

export default Tags;
