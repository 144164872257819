import React, { useState } from "react";
import Button from "../../components/Button";
import DonationForm from "../../components/forms/DonationForm";
import CardGroup from "../../components/cards/CardGroup";
import Page from "../../components/page_components/Page";
import { useSelector } from "react-redux";

import DonorBoard from "../../components/donor_board/DonorBoard";
import RichText from "../../components/RichText";

export default function Donate() {
  const [chequeShown, setChequeShown] = useState(false);
  const { donors } = useSelector((state) => state.donor);
  const { getInvolvedPage } = useSelector((state) => state.cms);
  let cms = getInvolvedPage;
  if (!cms) return null;
  console.log(cms);

  return (
    <Page sidebarType="getInvolved">
      <DonationForm cms={cms} />
      <h3 style={{ marginTop: "32px" }} className="hover txtCenter" onClick={() => setChequeShown((current) => !current)}>
        {cms.donatePageChequeLabel} <i className={chequeShown ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"}></i>
      </h3>
      {chequeShown && <RichText style={{ textAlign: "center", width: 300, margin: "auto" }} text={cms.donatePageChequeBody} />}

      {cms.donatePageShowDonors && donors?.recent?.length > 0 && (
        <>
          <h2 className="txtCenter eb">Recent Donors</h2>
          <DonorBoard donors={donors?.recent} />
          <Button buttonType="link" href="/getinvolved/donors" extraStyles={{ margin: "32px auto", marginBottom: "0" }}>
            View All Donors
          </Button>
        </>
      )}
      <div style={{ margin: "auto", width: "fit-content" }}>
        <h2 className="eb">{cms.donatePageCardsHeader}</h2>
        <CardGroup
          condenseOnMobile={true}
          cards={cms.getInvolvedCards.map((el) => {
            return {
              header: el.mainText,
              content: el.subText,
              img: el.image && el.image.url,
              buttonText: el.button && el.button[0] && el.button[0].buttonText,
              href: el.button && el.button[0] && el.button[0].linkUrl,
            };
          })}
        />
      </div>
    </Page>
  );
}
