import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../css/QuoteCard.css";

function QuoteCard() {
  const slug = window.location.pathname;
  const { testimonials } = useSelector((state) => state.testimonial);
  const { staff } = useSelector((state) => state.staff);
  const [data, setData] = useState();
  const [display, setDisplay] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [headshot, setHeadshot] = useState();

  // select the quotes for this page based on the slugs
  useEffect(() => {
    if (testimonials) {
      let tmp = [];
      testimonials.forEach((element) => {
        let slugs = element.pageSlug && element.pageSlug.filter((el) => el.text === slug);
        if (slugs.length >= 1) tmp.push(element);
      });

      // pick one to display at random
      let index = Math.floor(Math.random() * tmp.length);
      setData(tmp[index]);
    }
  }, [testimonials]);

  useEffect(() => {
    if (data) {
      const type = data.personalInfo[0].__component;
      let display;
      switch (type) {
        case "people.person":
          display = data.personalInfo[0];
          break;
        case "people.staff-component":
          display = { ...data.personalInfo[0].staff_member, __component: "people.staff-component" };
          break;
      }
      setDisplay(display);
    }
  }, [data]);

  useEffect(() => {
    let firstName, lastName, headshot;

    if (display && display.__component === "people.person") {
      firstName = display.firstName;
      lastName = display.lastName;
      headshot = display.headshot;
    } else if (display && display.__component === "people.staff-component") {
      if (staff) {
        const staffId = display.id;
        const match = staff.filter((el) => el.id === staffId)[0];
        firstName = match.personalInfo.firstName;
        lastName = match.personalInfo.lastName;
        headshot = match.personalInfo.headshot;
      }
    }

    setFirstName(firstName);
    setLastName(lastName);
    setHeadshot(headshot);
  }, [staff, display]);

  if (data && display) {
    return (
      <div className="QuoteCard">
        <i className="fa-solid fa-quote-left"></i>
        <div className="left">
          <p>{data.quote}</p>
          <p>
            <strong>
              - {firstName} {lastName}
            </strong>
          </p>
        </div>
        <div className="right">
          <img src={headshot && headshot.url} alt={`A photo of ${firstName}`} />
        </div>
      </div>
    );
  }
  return null;
}

export default QuoteCard;
