import { FETCH_NAV_FOOTER, FETCH_NAV_MAIN, FETCH_SERVICE_PAGES, FETCH_SECTIONS, SET_SERVICE_CMS, SET_GERMAN, FETCH_PAGE_CMS } from "../actions/cms";

const initialState = {
  servicePages: [],
  sections: [],
  servicesCms: null,
  mainNav: [],
  footerNav: [],
  isGermanMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAGE_CMS:
      let config = {};
      config[action.storeVariable] = action.data;
      return {
        ...state,
        ...config,
        // cms: action.data,
      };
    case FETCH_SERVICE_PAGES:
      return {
        ...state,
        servicePages: action.data,
      };

    case FETCH_SECTIONS:
      return {
        ...state,
        sections: action.data,
      };

    case SET_SERVICE_CMS:
      return {
        ...state,
        servicesCms: action.data,
      };

    case FETCH_NAV_MAIN:
      return {
        ...state,
        mainNav: action.data,
      };
    case FETCH_NAV_FOOTER:
      return {
        ...state,
        footerNav: action.data,
      };
    case SET_GERMAN:
      return {
        ...state,
        isGermanMode: action.isGermanMode,
      };
    default:
      return state;
  }
};
