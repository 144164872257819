import React from "react";
import Button from "../../components/Button";
import CardGroup from "../../components/cards/CardGroup";
import Page from "../../components/page_components/Page";
import PageHeader from "../../components/page_components/PageHeader";
import DonorBoard from "../../components/donor_board/DonorBoard";
import { useSelector } from "react-redux";

import "../../css/GolfPage.css";
import RichText from "../../components/RichText";

export default function GolfTournament() {
  const { donors } = useSelector((state) => state.donor);
  const { getInvolvedPage } = useSelector((state) => state.cms);
  let cms = getInvolvedPage;
  if (!cms) return null;
  console.log(cms);

  let showButton = new Date(cms?.golfPagePdfHideDate) >= new Date();
  return (
    <Page sidebarType="getInvolved">
      <PageHeader
        text={cms.golfPageTitle}
        style={{
          textAlign: "center",
          maxWidth: "400px",
          margin: "16px auto 64px auto",
        }}
      />

      <div className="golfBanner" style={{ marginBottom: "64px" }}>
        <div
          className="left"
          style={{
            backgroundImage: `url(${
              cms.golfPageImageLeft
                ? cms.golfPageImageLeft.url
                : "https://images.unsplash.com/photo-1593282153762-a41e3cceb06c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            })`,
            backgroundSize: "cover",
          }}
        ></div>

        <RichText style={{ fontSize: "15px", textAlign: "center" }} text={cms.golfPageBody} className="center eb" />

        <div
          className="right"
          style={{
            backgroundImage: `url(${
              cms.golfPageImageRight
                ? cms.golfPageImageRight.url
                : "https://images.unsplash.com/photo-1591491719565-9cae8fd89f8d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            })`,
            backgroundSize: "cover",
          }}
        ></div>
      </div>
      {showButton ? (
        <a className="Button rounded" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 75 }} href={cms?.golfPagePdf?.url} target="_blank" rel="noreferrer">
          Register Now!
        </a>
      ) : (
        <p style={{ textAlign: "center", marginBottom: 75 }}>Registration closed!</p>
      )}
      {/* {cms.golfPageCallToAction && (
        <div style={{ margin: "64px auto" }}>
          <p className="txtCenter" style={{ fontSize: "1.2rem" }}>
            <strong>{cms.golfPageCallToAction.mainText}</strong>
          </p>
          <a href="https://heidehof.s3.ca-central-1.amazonaws.com/Golf_Registration_form_a3921bd7ef.pdf" target="_blank" className="Button rounded light" style={{ margin: "0 auto" }}>
            Registration Form
          </a>
        </div>
      )} */}
      {/* maybe this could showcase the golf sponsors instead of the recent donors */}
      {cms.golfPageShowDonors && donors?.recent?.length > 0 && (
        <>
          <h2 className="txtCenter eb">Recent Donors</h2>
          <DonorBoard donors={donors?.recent} />
          <Button buttonType="link" href="/getinvolved/donors" extraStyles={{ margin: "32px auto", marginBottom: "0" }}>
            View All Donors
          </Button>
        </>
      )}

      <div style={{ margin: "auto", width: "fit-content" }}>
        <h2 className="eb" style={{ marginTop: "32px" }}>
          {cms.golfPageCardsHeader}
        </h2>
        <CardGroup
          condenseOnMobile={true}
          cards={cms.getInvolvedCards.map((el) => {
            return {
              header: el.mainText,
              content: el.subText,
              img: el.image && el.image.url,
              buttonText: el.button && el.button[0] && el.button[0].buttonText,
              href: el.button && el.button[0] && el.button[0].linkUrl,
            };
          })}
        />
      </div>
    </Page>
  );
}
