import React from "react";
import { Link } from "react-router-dom";
import "../css/Button.css";

export default function Button(props) {
  switch (props.buttonType) {
    case "default":
      return (
        <button
          onClick={props.onClick}
          className={props.light ? "Button rounded light " + props.className : "Button rounded " + props.className}
          style={{ width: props.width, ...props.extraStyles }}
          aria-label={props.ariaLabel}
          aria-expanded={props.ariaExpanded}
          tabIndex={props.tabIndex}
          disabled={props.disabled}
        >
          {props.children}
        </button>
      );
    case "icon":
      return (
        <button
          onClick={props.onClick}
          className={props.light ? "Button rounded light " + props.className : "Button rounded " + props.className}
          style={{ width: props.width, ...props.extraStyles }}
          aria-label={props.ariaLabel}
          aria-expanded={props.ariaExpanded}
          tabIndex={props.tabIndex}
          disabled={props.disabled}
        >
          {props.iconLeft && <img src={props.iconLeft} className="buttonIcon left iconSvg" />}
          {props.children}
          {props.iconRight && <img src={props.iconRight} className="buttonIcon right iconSvg" />}
        </button>
      );
    case "link":
      return (
        <Link
          to={props.href ? props.href : "#"}
          onClick={props.onClick}
          className={props.light ? "Button rounded light " + props.className : "Button rounded " + props.className}
          style={{ width: props.width, ...props.extraStyles }}
          aria-label={props.ariaLabel}
          aria-expanded={props.ariaExpanded}
          tabIndex={props.tabIndex}
        >
          {props.children}
        </Link>
      );
    case "submit":
      return (
        <input
          type="submit"
          value={props.children}
          className={props.light ? "Button rounded light " + props.className : "Button rounded " + props.className}
          style={{ width: props.width, padding: '0 24px', textAlign: 'center', ...props.extraStyles }}
          disabled={props.disabled}
        />
      );

    default:
      return null;
  }
}

Button.defaultProps = {
  buttonType: "default",
  className: "",
  light: false,
  onClick: undefined,
  width: "fit-content",
  extraStyles: {},
  icon: "",
  href: "",
  ariaLabel: undefined,
  ariaExpanded: undefined,
  tabIndex: 0,
  disabled: false,
};
