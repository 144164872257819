import React from "react";
import Button from "../Button";

export default function BookTourCTACard(props) {
  return (
    <div
      className="rounded"
      style={{
        width: "fit-content",
        padding: "32px",
        backgroundColor: "var(--light)",
        filter: "var(--shadow)",
        margin: "16px auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h3 style={{ margin: "0" }}>{props.mainText}</h3>
      <p style={{ margin: "8px 0", textAlign: "center" }}>{props.subText}</p>
      <Button buttonType="link" href={props.linkUrl}>
        {props.buttonText}
      </Button>
    </div>
  );
}

BookTourCTACard.defaultProps = {
  mainText: "Book a Tour",
  subText: "Visit our beautiful facilities today!",
  linkUrl: "/contact/book-tour",
  buttonText: "Book Today",
};
